import React, { useEffect, useState } from "react";
import YouTubePlayer from "youtube-player";
import * as s from "./player-styles";

import { SoundOutlined } from "@ant-design/icons";

const PlayerYoutube = ({ live, running, muted, setMuted }) => {
  const [player, setPlayer] = useState(null);
  // const [isMuted, setMuted] = useState(muted);

  function extractVideoId(url) {
    let videoId = "";

    // Verifica o formato da URL e extrai o ID do vídeo
    if (url.includes("youtu.be/")) {
      videoId = url.split("youtu.be/")[1];
    } else if (url.includes("youtube.com/watch?v=")) {
      const params = new URLSearchParams(url.split("?")[1]);
      videoId = params.get("v");
    } else if (url.includes("youtube.com/live/")) {
      videoId = url.split("youtube.com/live/")[1].split("?")[0];
    }

    return videoId;
  }

  useEffect(() => {
    const youtubeUrl = live?.video;

    const videoId = extractVideoId(youtubeUrl);

    const youtubePlayer = YouTubePlayer("player", {
      videoId: videoId,
      playerVars: {
        autoplay: 1,
        controls: 0,
        loop: 1,
        modestbranding: 1,
        showinfo: 0,
        color: "white",
      },
    });

    youtubePlayer.on("ready", () => {
      setPlayer(youtubePlayer);
      youtubePlayer.mute();
    });

    return () => {
      youtubePlayer.destroy();
    };
  }, []);

  useEffect(() => {
    if (player) {
      if (!running) {
        player.pauseVideo();
      } else {
        player.playVideo();
      }
    }
  }, [running]);

  useEffect(() => {
    if (player) {
      if (!muted) {
        player.unMute();
      } else {
        player.mute();
      }
    }
  }, [muted, player]);

  return (
    <s.PlayerContainer>
      <s.YoutubePlayerContainer>
        <div id="cropping-div" style={{ overflow: "hidden" }}>
          <div id="div-to-crop" className="div-to-crop">
            <div
              id="player-wrapper"
              style={{
                position: "relative",
                overflow: "hidden",
                paddingTop: "119%",
              }}
            >
              <div
                id="player"
                style={{
                  position: "absolute",
                  top: 0,
                  width: "100%",
                  height: "124%",
                  borderRadius: "12px",
                }}
              ></div>
              <div
                id="controls-overlay"
                style={{
                  position: "fixed",
                  bottom: 0,
                  left: 0,
                  width: "100%",
                  height: "100%",
                }}
              >
                {/* Botão de mudo
                <button
                  onClick={() => setMuted(!muted)}
                  style={{
                    position: "fixed",
                    top: "535px",
                    right: "435px",
                    zIndex: 1,
                    background: "none",
                    border: "none",
                    cursor: "pointer",
                  }}
                >
                  <s.Volume
                    className="volume-container"
                    onClick={() => setMuted(!muted)}
                  >
                    <SoundOutlined />
                    {muted && <div className="volume-muted" />}
                  </s.Volume>
                </button> */}
              </div>
            </div>
          </div>
        </div>
      </s.YoutubePlayerContainer>
    </s.PlayerContainer>
  );
};

export default PlayerYoutube;
