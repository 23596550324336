import Axios from "axios";
import { axiosApp } from ".";
//-------------------------------------------------------------
//---------------------------Live------------------------------
//-------------------------------------------------------------
const getLiveByUrl = (url) =>
  new Promise((resolve, reject) => {
    axiosApp
      .get(`customer/viewLive?friendlyUrl=${url}`)
      .then(({ data }) => {
        resolve(data);
      })
      .catch((e) => reject(e));
  });
//-------------------------------------------------------------
//---------------------------Message------------------------------
//-------------------------------------------------------------
const sendMessage = (liveId, salaId, body) =>
  new Promise((resolve, reject) => {
    axiosApp
      .post(`customer/mensagemChat/${liveId}/${salaId}`, body)
      .then(({ data }) => resolve(data))
      .catch((e) => reject(e));
  });
//-------------------------------------------------------------
//---------------------------Cart------------------------------
//-------------------------------------------------------------
const getCart = (live, codigo) =>
  new Promise((resolve, reject) => {
    axiosApp
      .get(`product/favoritos/${live}?codigo=${codigo}`)
      .then(({ data }) => resolve(data))
      .catch((e) => reject(e));
  });
const validateCep = (cep) =>
  new Promise((resolve, reject) => {
    Axios.get(`https://viacep.com.br/ws/${cep}/json`)
      .then(({ data }) => resolve(data))
      .catch((e) => reject(e));
  });
//-------------------------------------------------------------
//-----------------------Autentication-------------------------
//-------------------------------------------------------------
const customerLogin = (live, customer) =>
  new Promise((resolve, reject) => {
    axiosApp
      .post(`customer/registro/${live}`, customer)
      .then(({ data }) => resolve(data))
      .catch((e) => reject(e));
  });
const checkAudienceCustomer = (live, codigo) =>
  new Promise((resolve, reject) => {
    axiosApp
      .get(`/customer/validateAudience/${live}?codigo=${codigo}`)
      .then(({ data }) => resolve(data))
      .catch((e) =>
        reject(
          e && e.response && e.response.data && e.response.data.message
            ? e.response.data.message
            : false
        )
      );
  });
//-------------------------------------------------------------
//---------------------------Orders----------------------------
//-------------------------------------------------------------
const getOrders = (live, userCodigo) =>
  new Promise((resolve, reject) => {
    axiosApp
      .get(`customer/pedidos/${live}?codigo=${userCodigo}`)
      .then(({ data: { pedidos } }) => resolve(pedidos))
      .catch((e) => reject(e));
  });
//-------------------------------------------------------------
//--------------------------Shipping---------------------------
//-------------------------------------------------------------
const getShippingValue = (live, label) =>
  new Promise((resolve, reject) => {
    axiosApp
      .get(`customer/shippingValue/${live}/${label}`)
      .then(({ data }) => resolve(data))
      .catch((e) => reject(e));
  });
const getCorreiosValues = (body, liveId) =>
  new Promise((resolve, reject) => {
    axiosApp
      .post(`correios/calcularPrazoFrete/${liveId}`, body)
      .then(({ data }) => resolve(data))
      .catch((e) => reject(e));
  });
//-------------------------------------------------------------
//--------------------------Assinatura---------------------------
//-------------------------------------------------------------
const verifyAssinatura = (live, email) =>
  new Promise((resolve, reject) => {
    axiosApp
      .get(`customer/validateSignature/${live}/${email}`)
      .then(({ data }) => resolve(data))
      .catch((e) => reject(e));
  });

//-------------------------------------------------------------
//---------------------------Live------------------------------
//-------------------------------------------------------------
const getViewLeadByUrl = (url) =>
  new Promise((resolve, reject) => {
    axiosApp
      .get(`customer/viewLead?leadUrl=${url}`)
      .then(({ data }) => resolve(data))
      .catch((e) => reject(e));
  });

const createLead = (body, lead) =>
  new Promise((resolve, reject) => {
    axiosApp
      .post(`customer/subscribeLead/${lead}`, body)
      .then(({ data }) => resolve(data))
      .catch((e) => reject(e));
  });

const getCustomerLead = (lead, format) =>
  new Promise((resolve, reject) => {
    axiosApp
      .get(`report/customersLead/${lead}`, {
        params: { format },
        responseType: format === "excel" ? "arraybuffer" : "text",
      })
      .then(({ data }) => resolve(data))
      .catch((e) => {
        let error = e.response.data;
        if (format === "excel") {
          let decoder = new TextDecoder("utf-8");
          error = JSON.parse(decoder.decode(error));
        }
        reject(error?.message ? error.message : false);
      });
  });

export const customerService = {
  //live
  getLiveByUrl,
  //message
  sendMessage,
  //cart
  getCart,
  validateCep,
  //autentication
  customerLogin,
  checkAudienceCustomer,
  getCustomerLead,
  //orders
  getOrders,
  //shipping
  getShippingValue,
  getCorreiosValues,
  //assinatura
  verifyAssinatura,
  getViewLeadByUrl,
  createLead,
};
