import styled from "styled-components";
export const PlayerContainer = styled.div`
  width: 60rem;
  height: 100%;
  margin-top: -40px;
  margin-bottom: 0px;
  padding: 20px;
  border-radius: 12px;

  @media (max-width: 955px) {
    padding: 0;
    margin: 0;
    border-radius: 0px;
    box-shadow: none;
    height: auto;
  }
  /*
    ${({ vertical: v }) =>
    v ? `min-height: calc(100vh - 50px);min-width: 100vw;` : "z-index:3"}
  
  @media (max-width: 320px) {
    height: ${({ vertical: v }) => (v ? 0 : "auto")};
  } */
`;

export const YoutubePlayerContainer = styled.div`
  border-radius: 12px;
  height: 100%;
  @media (max-width: 955px) {
    height: auto;
    width: 100%;
  }
  #cropping-div {
    overflow: hidden;
    @media (min-width: 956px) {
      border-radius: 12px;
    }
  }

  #div-to-crop {
    overflow: hidden;
    margin-bottom: -20%;
    position: relative;
  }

  @media (max-width: 955px) {
    #div-to-crop {
      margin-top: -212px;
    }
  }

  @media (min-width: 956px) {
    #div-to-crop {
      margin-top: -473px;
    }
  }

  #player {
    position: absolute;
    top: 0;
    width: 100%;
    height: 124%;
  }
`;

export const Volume = styled.div`
  padding: 0 10px 0 10px;
  * {
    color: white !important;
    font-size: 20px;
  }
  .volume-muted {
    bottom: 3px;
    right: 18px;
    border-radius: 0;
    transform: rotate(45deg);
    border-left: 2px solid white;
    position: absolute;
    height: 20px;
  }
`;
