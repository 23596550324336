import React, { useContext, useState, useEffect, useCallback } from "react";
import { useTranslation } from "react-i18next";
import Hls from "hls.js";
import enableInlineVideo from "iphone-inline-video";
import { message, Dropdown, Menu, Spin, Tooltip } from "antd";
import {
  CaretRightOutlined,
  FullscreenOutlined,
  FullscreenExitOutlined,
  SoundOutlined,
  PauseOutlined,
  LoadingOutlined,
} from "@ant-design/icons";
import { firestore, googleTagManager, productService } from "../../../services";
import {
  duas_casas,
  formatCash,
  moveToRightElement,
  moveToElement,
  sourceImage,
  isIOS,
} from "../../../utils";
import * as s from "./styles-video";
import {
  AddProductContext,
  GradesContext,
  ThemeContext,
  UserContext,
  useTabsNavigation,
  ShowProductContext,
} from "../../../context";
import firebase from "firebase/app";
import "firebase/database";
import { ModalVariations } from "../../../components";
import Animate from "rc-animate";
import PlayerYoutube from "../yout-player/youtube";

Spin.setDefaultIndicator(
  <LoadingOutlined style={{ fontSize: "40px", color: "gray" }} />
);
const Video = ({ live }) => {
  const { REACT_APP_PROJECT_ID } = process.env;
  const { t } = useTranslation();
  const {
    tabActive,
    handleChangeTabActive,
    mainProductId,
    setMainProductId,
    setMainProductDocId,
  } = useTabsNavigation();
  const { show, setShow, show2, setShow2 } = useContext(ShowProductContext);
  const { video, intro, ladoCard, realtime } = live;
  let VOD = video.search("vod-4show") > 0;
  const { corPrimaria, corSecundaria, verticalChatHeight } =
    useContext(ThemeContext);
  const { addProduct, setAddProduct } = useContext(AddProductContext);
  const [products, setProducts] = useState([]); //todos os produtos da live
  const [cart, setCart] = useState([]); //ids dos produtos favoritos do usuário
  const { user } = useContext(UserContext); //dados do usuário
  const { userGrades } = useContext(GradesContext); //grades do owner da live
  // const [productId, setProductId] = useState(false); //id do produto para as variações
  const [modalVariationSoldOff, setModalVariationSoldOff] = useState(false);
  const [loadingVideoMetadata, setLoadingVideoMetadata] = useState(false);
  //Control
  // const [show, setShow] = useState(false); //produto exibido em cima
  // const [show2, setShow2] = useState(false); //produto exibido em baixo
  const [muted, setMuted] = useState(true); //vídeo mutado
  const [running, setRunning] = useState(false); //vídeo rodando
  const [time, setTime] = useState(0); //tempo de vídeo
  const [watchIntro, setWatchIntro] = useState(
    intro && !Boolean(localStorage.getItem(`${live.friendlyUrl}_playedIntro`))
      ? true
      : false
  );
  const [spectators, setSpectators] = useState(1);
  const [videoStarted, setVideoStarted] = useState(false);
  const [pauseCount, setPauseCount] = useState(0);
  const [resumeCount, setResumeCount] = useState(0);
  const [lastTimeBeforeJump, setLastTimeBeforeJump] = useState(false);
  const [timeJumped, setTimeJumped] = useState(false);
  //transmissão ao vivo
  const [levels, setLevels] = useState([]); //níveis de qualidade de vídeo
  const [hlsRef, setHlsRef] = useState(); //referência do hls
  const [isFullscreen, setIsFullscreen] = useState(false);
  const [showFullscreenBtn, setShowFullscreenBtn] = useState(true);
  const [levelSelected, setLevel] = useState(); //nível de qualidade atual
  const [hasVideo, setHasVideo] = useState(false);
  const [viewers, setViewsers] = useState(live?.viewers);
  const [showViewers, setShowViewers] = useState(live?.showViewers);
  const [showControls, setShowControls] = useState(true);
  const [showControlsTimeout, setShowControlsTimeout] = useState();
  const [showTooltipTimeout, setShowTooltipTimeout] = useState();
  const [showTooltip, setShowTooltip] = useState(true);

  //assistir vídeo introdutório
  const [playedIntro, setPlayedIntro] = useState(false); //se já tocou a introdução
  const [tnnInstanced, setTnnInstanced] = useState(false); //se já criou o script tnn

  //Video e Slider
  var videoHtml = document.getElementById("video");
  var sliderHtml = document.getElementById("slider");
  if (videoHtml) enableInlineVideo(videoHtml);
  useEffect(() => {
    window.addEventListener("storage", () => {
      if (localStorage.getItem(`${live.friendlyUrl}_playedIntro`)) {
        setPlayedIntro(true);
      }
    });
  }, []);

  useEffect(() => {
    if (showTooltip) {
      if (showTooltipTimeout) clearTimeout(showTooltipTimeout);
      setShowTooltipTimeout(
        setTimeout(() => {
          setShowTooltip(false);
        }, 6000)
      );
    }
  }, [showTooltip]);

  //control show controls
  useEffect(() => {
    if (showControls) {
      if (showControlsTimeout) clearTimeout(showControlsTimeout);
      setShowControlsTimeout(
        setTimeout(() => {
          setShowControls(false);
        }, 6000)
      );
    }
  }, [showControls]);

  useEffect(() => {
    if (live?.liveId && video) {
      let subscribe = firestore
        .collection("lives")
        .doc(live.liveId)
        .onSnapshot((snapshot) => {
          if (
            snapshot.data().video !== video &&
            live.aoVivo &&
            snapshot.data().video.includes("vod")
          ) {
            live.video = snapshot.data().video;
            live.aoVivo = false;
          }

          if (snapshot.data().viewers !== viewers) {
            setViewsers(snapshot.data().viewers);
          }
          if (snapshot.data().showViewers !== showViewers) {
            setShowViewers(snapshot.data().showViewers);
          }
        });
      return () => subscribe();
    }
  }, [live.liveId]);

  const formatTime = (time) => {
    if (time) {
      let hora = duas_casas(Math.trunc(time / 3600));
      let minuto = duas_casas(Math.trunc((time % 3600) / 60));
      let segundo = duas_casas((time % 3600) % 60).substring(0, 2);
      if (segundo === "9.") segundo = "09";
      return hora + ":" + minuto + ":" + segundo;
    }
    return "00:00:00";
  };

  function formatTimeVideo(seconds) {
    if (seconds) {
      if (!playedIntro) {
        var now = new Date().getTime() + seconds * 1000;
        var countDownDate =
          new Date().getTime() + parseInt(videoHtml.duration) * 1000;
        var distance = countDownDate - now;
        var minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
        var calcSeconds = Math.floor((distance % (1000 * 60)) / 1000);
        return duas_casas(minutes) + ":" + duas_casas(calcSeconds);
      } else return new Date(seconds * 1000).toISOString().substr(11, 8);
    }
    return "00:00";
  }

  window.onbeforeunload = function () {
    googleTagManager.liveWatched(
      formatTime(time),
      formatTime(document.getElementById("video").duration)
    );
  };

  useEffect(() => {
    if (lastTimeBeforeJump && timeJumped) {
      let timeBeforeFormatted = formatTime(parseInt(lastTimeBeforeJump));
      let jumpTimeFormatted = formatTime(parseInt(timeJumped));
      let direction =
        timeBeforeFormatted < jumpTimeFormatted ? "forward" : "backward";
      googleTagManager.liveJump(
        formatTime(parseInt(lastTimeBeforeJump)),
        formatTime(parseInt(timeJumped)),
        direction
      );
      setLastTimeBeforeJump(false);
      setTimeJumped(false);
    }
  }, [lastTimeBeforeJump, timeJumped]);

  // const playAndPause = useCallback(() => {
  //   if (videoHtml) {
  //     if (videoHtml.paused) {
  //       videoHtml.play();
  //       setRunning(true);
  //     } else {
  //       videoHtml.pause();
  //       setRunning(false);
  //     }
  //   }
  // }, [videoHtml]);

  const verifyAddProduct = (whatShow) => {
    if (live.checkout === "externo") {
      window.open(whatShow?.redirectLink);
    } else {
      let finish = () => setItem(whatShow, `poppup-live-${whatShow.docId}`);

      if (whatShow.available === false && !whatShow.esgotado)
        message.error(t("VIDEO.ProductUnavailableToBuy"));
      else if (whatShow.esgotado) openVariationModal(whatShow);
      else finish();
    }
  };

  useEffect(() => {
    if (videoHtml?.src) {
      videoHtml.onloadstart = function () {
        setLoadingVideoMetadata(true);
      };
    }
    if (!live.player === "youtube" && videoHtml?.src && !hasVideo) {
      videoHtml.onloadeddata = function () {
        setLoadingVideoMetadata(false);
        if (
          new Date().getTime() - live.dataInicio <
          videoHtml?.duration * 1000
        ) {
          setHasVideo(true);
          let difference = (new Date().getTime() - live.dataInicio) / 1000;
          videoHtml.currentTime = difference;
          videoHtml.play();
          setRunning(true);
        }
      };
    }
  }, [videoHtml?.src]);

  function handleScroll() {
    var videoContent = document.getElementById("player-container");
    var header = document.getElementById("header-4show");
    let screen = window.innerWidth;
    if (videoContent && header) {
      var sticky = videoContent.offsetTop;
      if (window.pageYOffset > sticky && screen < 955) {
        header.style.marginBottom = videoContent.offsetHeight + "px";
        videoContent.classList.add("sticky");
      } else {
        videoContent.classList.remove("sticky");
        header.style.marginBottom = "0px";
      }
    }
  }

  const verifyHasFullscreenBtn = useCallback(() => {
    if (
      !!navigator.userAgent.match(/iPhone|iPad|iPod/i) ||
      window.innerWidth > 955 ||
      !live?.vertical
    ) {
      setShowFullscreenBtn(false);
    }
  }, [live]);

  useEffect(() => {
    verifyHasFullscreenBtn();
    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, [verifyHasFullscreenBtn]);

  const handleFullscreen = () => {
    if (
      document?.webkitIsFullScreen ||
      document?.msIsFullScreen ||
      document?.mozIsFullScreen ||
      document?.isFullScreen
    ) {
      if (!isFullscreen) {
        document.exitFullscreen().then(() => {
          activeFullscreen();
          setIsFullscreen(true);
        });
      } else {
        document.exitFullscreen();
        setIsFullscreen(false);
      }
    } else {
      activeFullscreen();
      setIsFullscreen(true);
    }
  };

  const activeFullscreen = useCallback(() => {
    let elem = document.getElementById("fullscreenElem");

    if (elem.webkitRequestFullscreen) {
      elem.webkitRequestFullscreen();
    } else if (elem.msRequestFullscreen) {
      elem.msRequestFullscreen();
    } else if (elem.mozRequestFullscreen) {
      elem.mozRequestFullscreen();
    } else if (elem.requestFullscreen) {
      elem.requestFullscreen();
    }
  }, []);

  const getSubscribe = useCallback(() => {
    let subscribe = firestore.collection(`lives/${live.liveId}/produtos`);
    if (live?.tabelaPreco && user?.idTabelaPreco)
      subscribe = subscribe.where("tabelaPreco", "==", user.idTabelaPreco);
    subscribe = subscribe.onSnapshot((snapshot) => {
      setProducts(
        snapshot.docs
          .map(
            (doc) =>
              doc.data() &&
              doc.data().principal && {
                ...doc.data(),
                preco: parseFloat(doc.data().preco / 100),
                precoOriginal: parseFloat(doc.data().precoOriginal / 100),
                docId: doc.id,
              }
          )
          .filter((p) => p)
      );
    });
    return () => subscribe();
  }, [live, user]);

  useEffect(() => {
    getSubscribe();
  }, [getSubscribe]);

  const handleSubscribe = useCallback(() => {
    if (realtime && cart) {
      let subscribe = firestore
        .collection(`lives/${live?.liveId}/realtime`)
        .onSnapshot((s) => {
          setShow(false);
          setShow2(false);
          s.docs.forEach((d) => {
            if (
              d.id === "show1" &&
              !cart.includes(d.data().docId) &&
              addProduct?.docId !== d.data().docId
            ) {
              if (!d.data().controlStock)
                setShow({
                  ...d.data(),
                  preco: parseFloat(d.data().preco / 100),
                  precoOriginal: parseFloat(d.data().precoOriginal / 100),
                });
              else {
                let showWithStock = {
                  ...d.data(),
                  preco: parseFloat(d.data().preco / 100),
                  precoOriginal: parseFloat(d.data().precoOriginal / 100),
                };
                products.forEach((p) => {
                  if (showWithStock.docId === p.docId)
                    showWithStock = {
                      ...p,
                      positionNumber: d.data().positionNumber,
                    };
                });
                setShow(showWithStock);
              }
            }
            if (
              d.id === "show2" &&
              !cart.includes(d.data().docId) &&
              addProduct?.docId !== d.data().docId
            ) {
              if (!d.data().controlStock)
                setShow2({
                  ...d.data(),
                  preco: parseFloat(d.data().preco / 100),
                  precoOriginal: parseFloat(d.data().precoOriginal / 100),
                });
              else {
                let showWithStock = {
                  ...d.data(),
                  preco: parseFloat(d.data().preco / 100),
                  precoOriginal: parseFloat(d.data().precoOriginal / 100),
                };
                products.forEach((p) => {
                  if (showWithStock.docId === p.docId)
                    showWithStock = {
                      ...p,
                      positionNumber: d.data().positionNumber,
                    };
                });
                setShow2(showWithStock);
              }
            }
          });
        });
      return () => subscribe();
    }
  }, [live, realtime, cart]);

  useEffect(() => {
    handleSubscribe();
  }, [cart, handleSubscribe]);

  const handleCostumer = useCallback(() => {
    if (user && (user.email || (user.hash && user.anonimo))) {
      localStorage.setItem("customer", JSON.stringify(user));
      let userCod = user.codigo || user.hash;
      let subscribe2 = firestore
        .collection("lives")
        .doc(live.liveId)
        .collection("favoritos")
        .where("codigo", "==", userCod)
        .onSnapshot((snapshot) => {
          setCart(snapshot.docs.map((doc) => doc.data().produto.docId));
        });
      return () => subscribe2();
    }
  }, [live, user]);

  useEffect(() => {
    handleCostumer();
  }, [user, handleCostumer]);

  const playAndPauseYoutube = () => {
    setRunning((isRunning) => !isRunning);
  };
  const playAndPause = useCallback(
    (pauseCount, resumeCount, time) => {
      if (!watchIntro && live.aoVivo && live.player === "youtube") {
        playAndPauseYoutube();
      } else if (videoHtml) {
        let timeWatched = formatTime(time);
        let duration = formatTime(document.getElementById("video").duration);
        if (videoHtml.paused) {
          if (
            videoStarted &&
            (resumeCount || resumeCount === 0) &&
            timeWatched !== "00:00:00"
          ) {
            let countTmp = resumeCount + 1;
            googleTagManager.liveResume(timeWatched, duration, countTmp);
            setResumeCount(countTmp);
          }
          videoHtml.play();
          setRunning(true);
        } else {
          if (
            videoStarted &&
            (pauseCount || pauseCount === 0) &&
            timeWatched !== "00:00:00"
          ) {
            let countTmp = pauseCount + 1;
            googleTagManager.livePause(timeWatched, duration, countTmp);
            setPauseCount(countTmp);
          }
          videoHtml.pause();
          setRunning(false);
        }
      }
    },
    [videoHtml, videoStarted]
  );

  useEffect(() => {
    if (
      live?.aoVivo &&
      live?.streamAnalytics &&
      user?.codigo &&
      live?.liveId &&
      !tnnInstanced
    ) {
      setTnnInstanced(true);
      let userId = user.codigo || ""; // userID from platform
      let userEmail = user.email || ""; // userEmail from platform
      let mediaType = "live"; // live/vod
      let appId = live.streamAnalytics.appId; // appID from TNN Analytics
      let mediaID = live.liveId; // mediaID from platform
      let mediaTitle = live.nome; //mediaTitle from platform

      var script = document.createElement("script");
      script.textContent = `var userId = "${userId}";var userEmail = "${userEmail}";var mediaType = "${mediaType}";var appID = "${appId}";var mediaID = "${mediaID}";var mediaTitle = "${mediaTitle}";var player = { getCurrentTime: () => { let playerHtml = document.getElementById("video");if(playerHtml && playerHtml.currentTime) return currentTime; else return 0;}};`;
      document.getElementsByTagName("body")[0].appendChild(script);

      var scriptTnn = document.createElement("script");
      scriptTnn.src =
        "https://static.tvnanuvem.com/analytics/tnn_analytics.js?";
      document.getElementsByTagName("body")[0].appendChild(scriptTnn);
    }
  }, [user, live]);

  const playHls = useCallback(
    (url) => {
      if (Hls.isSupported()) {
        var hls = new Hls();
        hls.loadSource(url);
        hls.attachMedia(videoHtml);
        hls.on(Hls.Events.MEDIA_ATTACHED, function () {
          playAndPause(pauseCount, resumeCount, time);
          hls.on(Hls.Events.MANIFEST_PARSED, function (event, data) {
            //qualidades de vídeo
            setHlsRef(hls);
            setLevels(data.levels);
            hls.currentLevel = 1;
            setLevel(data.levels[1]);
          });
        });
        hls.on(Hls.Events.ERROR, function (event, data) {
          if (data.fatal) {
            switch (data.type) {
              case Hls.ErrorTypes.NETWORK_ERROR:
                message.error(t("VIDEO.ConnectionError"));
                hls.startLoad();
                break;
              case Hls.ErrorTypes.MEDIA_ERROR:
                message.error(t("VIDEO.MediaError"));
                hls.recoverMediaError();
                break;
              default:
                message.error(t("VIDEO.UnknowError"));
                hls.destroy();
                break;
            }
          }
        });
      } else if (
        videoHtml &&
        videoHtml.canPlayType("application/vnd.apple.mpegurl")
      ) {
        videoHtml.src = url;
        videoHtml.addEventListener("loadedmetadata", function () {
          playAndPause();
        });
      }
    },
    [playAndPause, videoHtml]
  );

  const handlePlayer = useCallback(() => {
    if (!watchIntro && live.aoVivo && live.player === "youtube") {
      playAndPauseYoutube();
    } else if (videoHtml) {
      if (watchIntro) {
        //assistir intro
        videoHtml.src = intro;
        playAndPause();
      } else {
        //assistir vídeo
        if (live.aoVivo || VOD)
          playHls(video); // vai dar start no youtube -fazer
        else {
          let localTime = localStorage.getItem(`${live.friendlyUrl}_timeVideo`); //tempo já assistido de vídeo
          videoHtml.src = video;
          if (localTime) videoHtml.currentTime = localTime;
          playAndPause();
        }
        localStorage.setItem(`${live.friendlyUrl}_playedIntro`, true); // assistiu a intro
        setPlayedIntro(true);
      }
    }
    document.title = `${live.nome} | 4Show`;
  }, [live, videoHtml, intro, playAndPause, playHls, video, watchIntro, VOD]);

  useEffect(() => {
    handlePlayer();
  }, [live, videoHtml, handlePlayer]);

  const handleIntro = useCallback(() => {
    if (playedIntro) {
      localStorage.setItem(`${live.friendlyUrl}_playedIntro`, true);
    }
  }, [playedIntro, live]);

  useEffect(() => {
    handleIntro();
  }, [playedIntro, handleIntro]);

  const handleVideoInfos = useCallback(() => {
    if (sliderHtml && videoHtml && videoHtml.duration && playedIntro) {
      let dataInicio = new Date(live.dataInicio).getTime();
      let agora = new Date().getTime();
      let fim = dataInicio + videoHtml.duration * 1000;
      if (fim > agora) sliderHtml.max = (agora - dataInicio) / 1000;
      else sliderHtml.max = videoHtml ? videoHtml.duration : 0;
    }
    if (playedIntro && !realtime) {
      localStorage.setItem(`${live.friendlyUrl}_timeVideo`, time);
      let hora = duas_casas(Math.trunc(time / 3600));
      let minuto = duas_casas(Math.trunc((time % 3600) / 60));
      let segundo = duas_casas((time % 3600) % 60).substring(0, 2);
      if (segundo === "9.") segundo = "09";
      let timeVideo = hora + ":" + minuto + ":" + segundo;
      products.forEach((p) => {
        if (timeVideo === p.tempoFim && show.docId === p.docId) {
          if (show2) {
            setShow(show2);
            setShow2(false);
          } else {
            setShow(false);
          }
        }
        if (timeVideo === p.tempoFim && show2.docId === p.docId) {
          setShow2(false);
        }
        if (!show || !show2) {
          if (timeVideo === p.tempoInicio) {
            if (
              !show &&
              !cart.includes(p?.docId) &&
              addProduct?.docId !== p?.docId
            ) {
              setShow(p);
            } else if (
              show &&
              show.docId !== p.docId &&
              !show2 &&
              !cart.includes(p?.docId)
            ) {
              setShow2(p);
            }
          }
        }
      });
    }
  }, [
    time,
    live,
    playedIntro,
    products,
    realtime,
    show,
    show2,
    sliderHtml,
    videoHtml,
    cart,
  ]);

  useEffect(() => {
    handleVideoInfos();
  }, [time, handleVideoInfos]);

  const removeShow = (product) => {
    if (show && product?.docId === show?.docId) {
      setShow(false);
    } else if (show2 && product?.docId === show2?.docId) {
      setShow2(false);
    }
  };

  const handleAddProductClosedGrid = (product) => {
    moveToElement(`card-${product?.docId}`, "order-header");
    let newProduct = {
      ...product,
      preco: product.controlStock
        ? parseFloat((product.preco * 100).toFixed(2))
        : getPriceFromProductRealtime(product, true).productPrice,
      precoOriginal: product.controlStock
        ? parseFloat((product.precoOriginal * 100).toFixed(2))
        : getPriceFromProductRealtime(product, true).originalProductPrice,
      //@todo refactor
      grade:
        product?.tipoGrade === "aberta" || !product?.tipoGrade
          ? [{ tamanho: product?.tamanhos[0], quantidade: 1 }]
          : userGrades?.filter((g) => product?.grades.includes(g.gradeId))[0]
              ?.grade || [],
      gradeId:
        product?.tipoGrade === "aberta" || !product?.tipoGrade
          ? null
          : userGrades?.filter((g) => product.grades.includes(g?.gradeId))[0]
              ?.gradeId || null,
    };
    googleTagManager.clickProduct(newProduct, "video");
    productService
      .addFavorite(live?.liveId, {
        produto: newProduct,
        customer: { ...user, codigo: user.codigo || user.hash },
      })
      .then(() => {
        googleTagManager.addProductToCart(newProduct, "video");
        message.success({ content: t("VIDEO.ProductAdded"), duration: 2 });
        removeShow(product);
      });
  };

  const handleAddProductOpenGrid = (product) => {
    moveToRightElement(
      `card-${product?.docId}`,
      shoudElementUseReverseMargin(product)
    );
    setTimeout(() => {
      let newProduct = {
        ...product,
        preco: product.controlStock
          ? parseFloat(product.preco)
          : getPriceFromProductRealtime(product).productPrice,
        precoOriginal: product.controlStock
          ? parseFloat(product.precoOriginal)
          : getPriceFromProductRealtime(product).originalProductPrice,
        //@todo refactor
        grade:
          product?.tipoGrade === "aberta" || !product?.tipoGrade
            ? [{ tamanho: product?.tamanhos[0], quantidade: 1 }]
            : userGrades?.filter((g) => product?.grades.includes(g.gradeId))[0]
                ?.grade || [],
        gradeId:
          product?.tipoGrade === "aberta" || !product?.tipoGrade
            ? null
            : userGrades?.filter((g) => product.grades.includes(g?.gradeId))[0]
                ?.gradeId || null,
      };
      googleTagManager.clickProduct(newProduct, "video");
      setAddProduct(newProduct);
      removeShow(product);
    }, 1000);
  };

  const setItem = (product) => {
    if (user && (user.email || (user.hash && user.anonimo))) {
      let podeEstarNoCarrinho =
        product.tipoGrade === "aberta" && live.layoutCheckout !== "padrao";
      if (
        (!cart.includes(product?.docId) || podeEstarNoCarrinho) &&
        addProduct?.docId !== product?.docId
      ) {
        if (product.tipoGrade === "aberta" && live.layoutCheckout !== "padrao")
          handleAddProductOpenGrid(product);
        else handleAddProductClosedGrid(product);
      } else {
        message.success({
          content: t("VIDEO.ProductAlreadyInCart"),
          duration: 2,
        });
        removeShow(product);
      }
    } else {
      document.getElementById(`btn-login-customer`).click();
      message.info({
        content: t("VIDEO.LoginToLike"),
        key: "user",
        duration: 3,
      });
    }
  };

  useEffect(() => {
    if (live?.liveId) {
      const userRef = firebase
        .database()
        .ref(`${live.liveId}/online`)
        .push(true);
      if (user) userRef.set(user);
      userRef.onDisconnect().remove();
      return () => userRef.remove();
    }
  }, [user, live]);

  const handleSpectators = useCallback(() => {
    firebase
      .database()
      .ref(`${live?.liveId}/online`)
      .on("value", (s) => setSpectators(s.numChildren()));
  }, [live]);

  useEffect(() => {
    handleSpectators();
  }, [handleSpectators]);

  const getPriceFromProductRealtime = (product, convertToCent) => {
    let productPrice = 0;
    let originalProductPrice = 0;

    if (
      live.tabelaPreco &&
      product.tabelaPreco &&
      Array.isArray(product.tabelaPreco)
    ) {
      if (user.idTabelaPreco) {
        productPrice = product.tabelaPreco.filter(
          ({ tabelaPreco }) => tabelaPreco === user.idTabelaPreco
        )[0].valor;
        productPrice = productPrice;
      }
    } else {
      productPrice = product.preco;
    }
    originalProductPrice = product.precoOriginal;
    if (convertToCent) {
      productPrice = parseFloat(productPrice * 100).toFixed(2);
      originalProductPrice = parseFloat(originalProductPrice * 100).toFixed(2);
    }
    return { productPrice, originalProductPrice };
  };

  var maxSliderWidth = document.getElementById("controls")
    ? document.getElementById("controls").clientWidth
    : 0;

  const openVariationModal = (p) => {
    if (window.innerWidth > 955) setModalVariationSoldOff(true);
    else {
      handleChangeTabActive("variations");
      setAddProduct(false);
    }
    setMainProductId(p.id);
    setMainProductDocId(p.docId);
  };

  const renderLevelSelector = () => (
    <Dropdown
      style={{ margin: 0 }}
      placement="topCenter"
      overlay={
        <Menu>
          {levels &&
            [...levels].reverse().map((l, i) => (
              <Menu.Item
                key={l.height}
                onClick={() => {
                  setLevel(l);
                  hlsRef.loadLevel = levels.indexOf(l);
                  hlsRef.nextLevel = levels.indexOf(l);
                }}
                style={{ lineHeight: "15px" }}
              >
                <label style={{ fontSize: "13px" }}>{l.height}p</label>
              </Menu.Item>
            ))}
        </Menu>
      }
    >
      <button
        style={{
          background: "transparent",
          color: "white",
          border: "none",
          outline: 0,
          fontSize: "13px",
          margin: "0 10px 0 0",
          cursor: "pointer",
        }}
      >
        {levelSelected && levelSelected.height + "p"}
      </button>
    </Dropdown>
  );
  const shoudElementUseReverseMargin = (whatShow) => {
    return (
      (live?.vertical &&
        (whatShow?.positionNumber === 2 ||
          whatShow?.positionNumber === 4 ||
          whatShow?.positionNumber === 6)) ||
      whatShow?.positionNumber === 3 ||
      whatShow?.positionNumber === 6 ||
      (!live?.realtime && live?.ladoCard === "direita")
    );
  };

  const renderShow = (whatShow) => (
    <s.Poppup
      corPrimaria={corPrimaria}
      corSecundaria={corSecundaria}
      // vertical={live.vertical}
      // realtime={live.realtime}
      // ladoCard={ladoCard}
      ladoCard="direita"
      aoVivo={live.aoVivo}
      showOne={whatShow?.positionNumber === show?.positionNumber}
      positionNumber={whatShow?.positionNumber}
      show1PosNumber={show?.positionNumber}
      show2PosNumber={show2?.positionNumber}
      bottom={whatShow === show2}
      right={
        whatShow?.positionNumber === 2 ||
        whatShow?.positionNumber === 4 ||
        whatShow?.positionNumber === 6
      }
      indisponivel={whatShow.available === false || whatShow.esgotado}
      onClick={() => verifyAddProduct(whatShow)}
      id={`card-${whatShow.docId}`}
    >
      {/* <article>
        <h5>{live?.unidadeMonetaria}</h5>
        {formatCash(
          getPriceFromProductRealtime(whatShow),
          true,
          live?.unidadeMonetaria
        )}
      </article> */}
      <img
        className="img-product"
        src={sourceImage(whatShow.thumbnail)}
        alt={`Image1 ${whatShow.docId}`}
        id={`poppup-live-${whatShow.docId}`}
        onError={() => {
          let e = document.getElementById(`poppup-live-${whatShow.docId}`);
          e.src =
            "https://smartcharge.com.br/images/paginas/pagina-default.png";
        }}
      />
      {whatShow.available === false || whatShow.esgotado ? (
        <label style={{ textTransform: "uppercase" }}>
          {whatShow.esgotado
            ? t("VIDEO.ProductSoldoff")
            : t("VIDEO.ProductUnavailable")}
        </label>
      ) : (
        <div>
          {!getPriceFromProductRealtime(whatShow).originalProductPrice ||
          getPriceFromProductRealtime(whatShow).originalProductPrice ===
            getPriceFromProductRealtime(whatShow).productPrice ? (
            <article className="product-price">
              <h5>{live?.unidadeMonetaria || "R$"}</h5>
              {getPriceFromProductRealtime(whatShow).productPrice &&
                formatCash(
                  getPriceFromProductRealtime(whatShow).productPrice,
                  true,
                  live?.unidadeMonetaria
                )}
            </article>
          ) : (
            <div>
              <article
                className="product-price"
                style={{
                  textDecoration: "line-through",
                  color: "#c4c4c4",
                  fontSize: "11px",
                  padding: "2px 10px",
                  marginBottom: "1px",
                }}
              >
                <h5>{live?.unidadeMonetaria || "R$"}</h5>
                {getPriceFromProductRealtime(whatShow).originalProductPrice &&
                  formatCash(
                    getPriceFromProductRealtime(whatShow).originalProductPrice,
                    true,
                    live?.unidadeMonetaria
                  )}
              </article>
              <article
                className="product-price"
                style={{
                  fontSize: "13px",
                  padding: "2px 10px",
                  marginTop: "2px",
                }}
              >
                <h5>{live?.unidadeMonetaria || "R$"}</h5>
                {getPriceFromProductRealtime(whatShow).productPrice &&
                  formatCash(
                    getPriceFromProductRealtime(whatShow).productPrice,
                    true,
                    live?.unidadeMonetaria
                  )}
              </article>
            </div>
          )}
        </div>

        // <article className="product-price">
        //   <h5>{live?.unidadeMonetaria}</h5>
        //   {formatCash(
        // getPriceFromProductRealtime(whatShow).productPrice,
        //     true,
        //     live?.unidadeMonetaria
        //   )}
        // </article>
      )}
      {/* {whatShow.available && !whatShow.esgotado && (
        <section>
          <img
            src={"/assets/icons/shoppingOutlined.svg"}
            alt="Ícone do carrinho de compras"
          />
        </section>
      )} */}
    </s.Poppup>
  );

  const handleVideoClick = () => {
    if (
      !live.aoVivo &&
      new Date().getTime() - live.dataInicio > videoHtml?.duration * 1000
    ) {
      if (!showControls) setShowControls(true);
      if (muted) setMuted(false);
      else if (
        watchIntro ||
        window.innerWidth >= 955 ||
        live.aoVivo ||
        (live.vertical && window.innerWidth < 955)
      )
        if (showControls) playAndPause(pauseCount, resumeCount, time);
    }
  };

  return (
    <s.PlayerContainer
      className="shadow"
      id="player-container"
      vertical={live.vertical}
    >
      <ModalVariations
        live={live}
        customer={user}
        products={products
          .filter((p) => p.id === mainProductId)
          .filter((p) => !p.principal)
          .filter(
            (p) =>
              !cart.map((f) => f).includes(p.docId) &&
              addProduct?.docId !== p.docId
          )}
        visible={modalVariationSoldOff}
        onCancel={() => setModalVariationSoldOff(false)}
      />

      <s.VideoContainer
        disabled={watchIntro}
        vertical={live.vertical}
        running={running}
        ios={live.vertical && isIOS()}
      >
        {REACT_APP_PROJECT_ID === "show-hml" && (
          <s.WaterMark onClick={() => handleVideoClick()}>
            {t("VIDEO.DemonstrationLive")}
          </s.WaterMark>
        )}
        {/* // @todo - Ajustar depois no novo layout */}
        {/* {live.vertical && live.realtime ? (
          <>
            <s.RealtimeCardWrapper onClick={() => handleVideoClick()}>
              {show?.positionNumber === 1 && renderShow(show)}
              {show2?.positionNumber === 1 && renderShow(show2)}
              {show?.positionNumber === 3 && renderShow(show)}
              {show2?.positionNumber === 3 && renderShow(show2)}
              {show?.positionNumber === 5 && renderShow(show)}
              {show2?.positionNumber === 5 && renderShow(show2)}
            </s.RealtimeCardWrapper>
            <s.RealtimeCardWrapper left onClick={() => handleVideoClick()}>
              {show?.positionNumber === 2 && renderShow(show)}
              {show2?.positionNumber === 2 && renderShow(show2)}
              {show?.positionNumber === 4 && renderShow(show)}
              {show2?.positionNumber === 4 && renderShow(show2)}
              {show?.positionNumber === 6 && renderShow(show)}
              {show2?.positionNumber === 6 && renderShow(show2)}
            </s.RealtimeCardWrapper>
          </>
        ) : (
          <>
            <Animate transitionName="fade">
              {show ? renderShow(show) : null}
            </Animate>
            <Animate transitionName="fade">
              {show2 ? renderShow(show2) : null}
            </Animate>
          </>
        )} */}

        {((live?.vertical && window.innerWidth < 955) ||
          window.innerWidth > 955) && (
          <>
            <Animate transitionName="fade">
              {show ? renderShow(show) : null}
            </Animate>
            <Animate transitionName="fade">
              {show2 ? renderShow(show2) : null}
            </Animate>
          </>
        )}

        {!running || (watchIntro && videoHtml && videoHtml.paused) ? (
          <CaretRightOutlined
            alt="icone-play-player-fake-live"
            style={{
              position: "absolute",
              color: "white",
              fontSize: "calc(20px + 1vw)",
              zIndex: "2",
            }}
            onClick={playAndPause}
          />
        ) : null}
        {running && muted && (
          <s.MutedVideo
            showControls={showControls}
            onClick={() => {
              if (!showControls) setShowControls(true);
              setMuted(false);
            }}
            onMouseOver={() => {
              if (!showControls) setShowControls(true);
            }}
            onmouseleave={() => {
              if (showControls) setShowControls(false);
            }}
          >
            <s.VolumeContainer>
              <div className="line-muted-video" />
              <SoundOutlined
                alt="icone-mudo-player-fake-live"
                style={{
                  color: "white",
                  fontSize: "calc(20px + 1vw)",
                  zIndex: "2",
                }}
              />
            </s.VolumeContainer>
          </s.MutedVideo>
        )}
        {loadingVideoMetadata && (
          <div className="loading-video-metadata fade-in">
            <Spin />
            <label>{t("VIDEO.LoadingVideo")}</label>
          </div>
        )}

        {!watchIntro && live?.player === "youtube" ? (
          <PlayerYoutube
            live={live}
            running={running}
            muted={muted}
            setMuted={setMuted}
          ></PlayerYoutube>
        ) : (
          <s.Video
            onContextMenu={(e) => e.preventDefault()}
            muted={muted}
            controls={false}
            autoPlay
            playsInline
            onMouseOver={() => {
              if (!showControls) setShowControls(true);
            }}
            onmouseleave={() => {
              if (showControls) setShowControls(false);
            }}
            id="video"
            onLoadedMetadata={() => {
              setTimeJumped(false);
              if (!videoStarted) {
                setVideoStarted(true);
                googleTagManager.liveStart();
              }
            }}
            onClick={() => handleVideoClick()}
            onTimeUpdate={(e) => {
              if (sliderHtml && !live.aoVivo)
                sliderHtml.value = e.currentTarget.currentTime;
              setTime(e.currentTarget.currentTime);
            }}
            onEnded={() => {
              if (watchIntro) {
                setTime(0);
                setWatchIntro(false);
                localStorage.setItem(`${live.friendlyUrl}_playedIntro`, true); // assistiu a intro
                if (sliderHtml) sliderHtml.value = 0;
                if (live.aoVivo || VOD) playHls(video);
              } else if (videoHtml?.duration === videoHtml?.currentTime) {
                setRunning(false);
                googleTagManager.liveEnd();
              } else {
                videoHtml.src = video;
                playAndPause();
              }
            }}
          />
        )}

        {live.aoVivo ||
        new Date().getTime() - live.dataInicio < videoHtml?.duration * 1000 ? (
          <>
            <s.TagAoVivo>{t("VIDEO.Live")}</s.TagAoVivo>
            {live.vertical && showViewers && (
              <s.TagOnlineViewers aoVivo>
                <img src="/assets/icons/Viewers.svg" alt="viewers-online" />
                {spectators + viewers}
              </s.TagOnlineViewers>
            )}
            {!live.vertical && showViewers && window.innerWidth > 955 && (
              <s.TagOnlineViewers aoVivo>
                <img src="/assets/icons/Viewers.svg" alt="viewers-online" />
                {spectators + viewers}
              </s.TagOnlineViewers>
            )}
            {!live.vertical && showViewers && window.innerWidth < 955 && (
              <s.TagOnlineViewersHorizontal aoVivo>
                <img src="/assets/icons/Viewers.svg" alt="viewers-online" />
                {spectators + viewers}
              </s.TagOnlineViewersHorizontal>
            )}
            {live.player === "youtube" && (
              <s.SoundIconYoutube>
                <s.Volume
                  className="volume-container"
                  onClick={() => setMuted(!muted)}
                >
                  <SoundOutlined />
                  {muted && <div className="volume-muted" />}
                </s.Volume>
              </s.SoundIconYoutube>
            )}

            {levels && levels.length > 0 && (
              <s.LevelsControl
                id="controls"
                vertical={live.vertical && window.innerWidth < 955}
                verticalChatHeight={verticalChatHeight}
                showViewers={showViewers}
                showControls={showControls}
              >
                {/* // @todo - Usuarios online vao aparecer no video */}
                {/* {showViewers && (
                  <s.UserOnline style={{ height: 35 }}>
                    <UserOutlined />
                    {spectators + viewers}
                  </s.UserOnline>
                )} */}

                <div
                  style={{
                    display: "flex",
                    marginRight: 10,
                    marginLeft: "auto",
                  }}
                >
                  {renderLevelSelector()}
                  <s.Volume
                    className="volume-container"
                    onClick={() => setMuted(!muted)}
                  >
                    <SoundOutlined />
                    {muted && <div className="volume-muted" />}
                  </s.Volume>
                </div>
              </s.LevelsControl>
            )}
          </>
        ) : (
          <>
            {showViewers && (
              <s.TagOnlineViewers aoVivo={live.aoVivo}>
                <img src="/assets/icons/Viewers.svg" alt="viewers-online" />
                {spectators + viewers}
              </s.TagOnlineViewers>
            )}
            <s.Controls
              id="controls"
              vertical={live.vertical && window.innerWidth < 955}
              verticalChatHeight={verticalChatHeight}
              tabActive={tabActive}
              showControls={showControls}
              onMouseOver={() => {
                if (!showControls) setShowControls(true);
              }}
              onmouseleave={() => {
                if (showControls) setShowControls(false);
              }}
            >
              <div>
                <s.PlayAndPause
                  onClick={() => playAndPause(pauseCount, resumeCount, time)}
                  disabled={watchIntro}
                >
                  {running ? <PauseOutlined /> : <CaretRightOutlined />}
                </s.PlayAndPause>
              </div>
              <s.Slider
                id="slider"
                disabled={watchIntro}
                type="range"
                corPrimaria={corPrimaria}
                width={
                  videoHtml
                    ? watchIntro
                      ? (videoHtml.currentTime / videoHtml.duration) *
                          maxSliderWidth +
                        "px"
                      : "100%"
                    : "0%"
                }
                onMouseUp={(e) => setTimeJumped(e.target.value)}
                onMouseDown={(e) => setLastTimeBeforeJump(e.target.value)}
                onChange={(e) => {
                  videoHtml.currentTime = e.target.value;
                  setShow(false);
                  setShow2(false);
                }}
              />
              {watchIntro && <s.Intro />}
              <Tooltip
                title={t("VIDEO.ActivateLiveAudio")}
                color="rgba(97, 97, 97, 0.9)"
                open={showTooltip && muted}
                overlayInnerStyle={{
                  textAlign: "center",
                  fontSize: "1rem",
                  fontWeight: 500,
                  borderRadius: "4px",
                  width: "177px",
                }}
              >
                <s.Volume
                  className="volume-container"
                  onClick={() => setMuted(!muted)}
                >
                  <SoundOutlined />
                  {muted && <div className="volume-muted" />}
                </s.Volume>
              </Tooltip>
              {VOD && levels && levels.length > 0 && renderLevelSelector()}
              <label
                style={{
                  margin: "0 10px 0 0",
                  minWidth: "50px",
                  fontSize: "13px",
                }}
              >
                {time ? formatTimeVideo(time) : "00:00:00"}
              </label>

              {showFullscreenBtn && (
                <div>
                  <s.ToggleFullscreen onClick={handleFullscreen}>
                    {!isFullscreen ? (
                      <FullscreenOutlined />
                    ) : (
                      <FullscreenExitOutlined />
                    )}
                  </s.ToggleFullscreen>
                </div>
              )}
            </s.Controls>
          </>
        )}
      </s.VideoContainer>
    </s.PlayerContainer>
  );
};

export default Video;
