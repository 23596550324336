import React, { useContext, useEffect, useState, useCallback } from "react";
import { useTranslation } from "react-i18next";
import Calendar from "react-calendar";
import {
  Input,
  InputNumber,
  Button,
  Switch,
  Radio,
  Skeleton,
  Card,
  Modal,
  Tag,
  notification,
  message,
  Select,
  Checkbox,
  Statistic,
} from "antd";
import {
  RightOutlined,
  LeftOutlined,
  ArrowRightOutlined,
  ScheduleOutlined,
  SaveOutlined,
  PlusOutlined,
  GlobalOutlined,
  ShoppingCartOutlined,
  InboxOutlined,
  TableOutlined,
  EnvironmentOutlined,
  UsergroupAddOutlined,
  UnorderedListOutlined,
  TagOutlined,
  MenuFoldOutlined,
  FieldTimeOutlined,
} from "@ant-design/icons";
import * as s from "./styles";
import "./calendar.css";
import moment from "moment";
import "moment/locale/pt-br";
import {
  convertImgToBase64URL,
  handleChangeTime,
  preventInvalidNumberOnKeyDown,
  preventInvalidNumberOnPaste,
  onlyMonetaryTypes,
} from "../../../../utils";
import { managerService, neomodeService } from "../../../../services";
import ModalSplits from "../modal-splits";
import ModalShipping from "./modal-shipping";
import ModalAviso from "./modal-aviso";
import shippingJson from "./shipping.json";
import ModalRetirada from "./modal-retirada";
import OrientationContainer from "./orientation-container/orientation-container";
import { CropImage } from "../../../../components";
import ModalAssinantes from "./modal-assinantes/modal-assinantes";
import { UserContext } from "../../../../context";
import { withRouter } from "react-router";
import AudienceCard from "./audience-card/audience-card";
import Scrollbars from "react-custom-scrollbars";
import ModalSplitFrete from "./modal-split-frete";

const FORMA_ENTREGA_MODEL = {
  freteProprio: false,
  correios: false,
  retirada: false,
  freteGratis: { valorMinimo: false },
};

const { TextArea, Search } = Input;
const { Option } = Select;
const { Countdown } = Statistic;
const LiveTab = ({
  next,
  live,
  setRealtime: setReal,
  setAoVivo: setVivo,
  setLive,
  setLiveWithAssinatura,
  history,
  setLiveWithTabelaPreco,
}) => {
  const { t } = useTranslation();
  const { user } = useContext(UserContext);
  //Live values
  const [nome, setNome] = useState("");
  const [thumb, setThumb] = useState();
  const [errorThumb, setErrorThumb] = useState(false);
  const [nameThumbnail, setNameThumbnail] = useState();
  const [descricao, setDescricao] = useState("");
  const [timeStart, setTimeStart] = useState("");
  const [timeEnd, setTimeEnd] = useState("");
  const [dateValue, onChangeDateValue] = useState([new Date(), new Date()]);
  // const [minDateCalendar, setMinDateCalendar] = useState(new Date());
  const [ladoCard, setLadoCard] = useState("direita");
  const [layoutCheckout, setLayoutCheckout] = useState("ecommerce");
  const [invalidValues, setInvalidValues] = useState([]);
  const [liveEstrangeira, setLiveEstrangeira] = useState(false);
  const [unidadeMonetaria, setUnidadeMonetaria] = useState("R$");
  const [autoStart, setAutoStart] = useState(true);
  const [started, setStarted] = useState(false);
  const [render, setRender] = useState(false); //para atualizar o botão de iniciar live
  //shipping
  const [splitRulesFrete, setSplitRulesFrete] = useState(false);
  const [tipoFrete, setTipoFrete] = useState("nenhum");
  const [formaEntrega, setFormaEntrega] = useState(FORMA_ENTREGA_MODEL);

  const [retirada, setRetidada] = useState(false);
  const [modalRetirada, setModalRetirada] = useState(false);
  const [modalShipping, setModalShipping] = useState(false);
  const [modalSplitFrete, setModalSplitFrete] = useState(false);
  const [decrescimo, setDecrescimo] = useState(false); //decréscimo frete
  const [modalAssinantes, setModalAssinantes] = useState(false);
  //payment types
  const [condicao, setCondicao] = useState("livre");
  const [condicoes, setCondicoes] = useState(["1", "2", "3"]);
  const [pagarmeCondicoes, setPagarmeCondicoes] = useState([]);
  const [parcelaMinima, setParcelaMinima] = useState(0);
  //Transmition
  const [aoVivo, setAoVivo] = useState(false);
  const [realtime, setRealtime] = useState(false);
  const [orientation, setOrientation] = useState("horizontal");
  const [player, setPlayer] = useState(user?.player[1] ?? user.player[0]);
  const [urlYoutube, setUrlYoutube] = useState(live?.video || "");

  //Checkout
  const [formaPagto, setFormaPagto] = useState(["cc"]); //cc (cartão de crédito) || pix
  const [checkout, setCheckout] = useState("interno"); //interno || vtex || magento
  const [awinAdvertiserId, setAwinAdvertiserId] = useState(false); //Id de tracking da awin
  const [checkoutWarning, setCheckoutWarning] = useState(false);
  const [catalogWarning, setCatalogWarning] = useState(false);
  const [listaAssinatura, setListaAssinatura] = useState(false);
  const [listaAudiencia, setListaAudiencia] = useState(false);
  const [tabelaPreco, setTabelaPreco] = useState(false);
  const [valorPedidoMinimo, setValorPedidoMinimo] = useState(0); // valor minimo de compra
  //Control
  const [saving, setSaving] = useState(false); //salvando dados
  const [splitsModal, setSplitsModal] = useState(false); //modal para cadastrar o id no 4sdhow
  const [cropImage, setCropImage] = useState(false); //modal para formatar thumbnail
  const [openWarningModal, setOpenWarningModal] = useState(false);
  //Catalogo
  const [catalogService, setCatalogService] = useState("interno"); //interno || vtex || magento || neomode
  //Magento
  const [alreadyUseMagento, setAlreadyUseMagento] = useState(false);
  const [magentoURL, setMagentoURL] = useState("");
  const [consumerKey, setConsumerKey] = useState("");
  const [consumerSecret, setConsumerSecret] = useState("");
  const [accessToken, setAccessToken] = useState("");
  const [tokenSecret, setTokenSecret] = useState("");
  const [sizeAttributeName, setSizeAttributeName] = useState("");
  //VTEX
  const [alreadyUseVtex, setAlreadyUseVtex] = useState(false);
  const [appKey, setAppKey] = useState("");
  const [appToken, setAppToken] = useState("");
  const [accountName, setAccountName] = useState("");
  const [environment, setEnvironment] = useState("");
  const [urlEcommerce, setUrlEcommerce] = useState("");
  const optionsType = ["Seller", "Marketplace"];
  const [selectedType, setSelectedType] = useState("");
  //NEOMODE
  const [alreadyUseNeomode, setAlreadyUseNeomode] = useState(false);
  const [clientId, setClientId] = useState("");
  const [clientSecret, setClientSecret] = useState("");
  const [scope, setScope] = useState("");
  //GTM
  const [googleTag, setGoogleTag] = useState("");

  const setVtexKeys = () => {
    if (!appKey) setAppKey(alreadyUseVtex ? alreadyUseVtex.appKey : "");
    if (!appToken) setAppToken(alreadyUseVtex ? alreadyUseVtex.appToken : "");
    if (!accountName)
      setAccountName(alreadyUseVtex ? alreadyUseVtex.accountName : "");
    if (!environment)
      setEnvironment(alreadyUseVtex ? alreadyUseVtex.environment : "");
    if (!urlEcommerce)
      setUrlEcommerce(alreadyUseVtex ? alreadyUseVtex.urlEcommerce : "");
    if (!selectedType)
      setSelectedType(
        alreadyUseVtex
          ? alreadyUseVtex.type[0].toUpperCase() + alreadyUseVtex.type.substr(1)
          : ""
      );
  };

  const setMagentoKeys = () => {
    if (!magentoURL)
      setMagentoURL(alreadyUseMagento ? alreadyUseMagento.magentoURL : "");
    if (!consumerKey)
      setConsumerKey(alreadyUseMagento ? alreadyUseMagento.consumerKey : "");
    if (!consumerSecret)
      setConsumerSecret(
        alreadyUseMagento ? alreadyUseMagento.consumerSecret : ""
      );
    if (!accessToken)
      setAccessToken(alreadyUseMagento ? alreadyUseMagento.accessToken : "");
    if (!tokenSecret)
      setTokenSecret(alreadyUseMagento ? alreadyUseMagento.tokenSecret : "");
    if (!sizeAttributeName)
      setSizeAttributeName(
        alreadyUseMagento ? alreadyUseMagento.sizeAttributeName : ""
      );
  };

  useEffect(() => {
    if (dateValue && dateValue[0] && timeStart && timeEnd) {
      checkInvalidValues("timeLive");
    }
  }, [dateValue]);

  useEffect(() => {
    if (user?.integrations?.payment) {
      if (
        !alreadyUseVtex &&
        user.integrations.payment.vtex &&
        Object.keys(user.integrations.payment.vtex)?.length > 0
      ) {
        setAlreadyUseVtex(user.integrations.payment.vtex);
      }
      if (
        !alreadyUseMagento &&
        user.integrations.payment.magento &&
        Object.keys(user.integrations.payment.magento)?.length > 0
      )
        setAlreadyUseMagento(user.integrations.payment.magento);
      if (!alreadyUseNeomode && user?.integrations?.payment?.neomode)
        setAlreadyUseNeomode(user.integrations.payment.neomode);
    }
  }, [user, alreadyUseVtex, alreadyUseMagento, alreadyUseNeomode]);

  useEffect(() => {
    if (live) {
      if (live.catalog === "vtex") {
        if (live.checkout === "vtex") setCheckout("vtex");
        setVtexKeys();
      } else if (live.catalog === "magento") {
        if (live.checkout === "magento") setCheckout("magento");
        setMagentoKeys();
      } else if (live.catalog === "neomode") {
        if (live.checkout === "neomode") setCheckout("neomode");
        setClientId(alreadyUseNeomode ? alreadyUseNeomode.clientId : "");
        setClientSecret(
          alreadyUseNeomode ? alreadyUseNeomode.clientSecret : ""
        );
        setScope(alreadyUseNeomode ? alreadyUseNeomode.scope : "");
      } else if (live.checkout === "externo") {
        setCheckout("externo");
      } else {
        setCheckout(`interno`);
      }
      if (live.formaEntrega) setFormaEntrega(live.formaEntrega);

      if (live.formaEntrega?.retirada) {
        setRetidada(live.formaEntrega.retirada);
      }
      if (live.formaEntrega?.correios) {
        setTipoFrete("correios");
        if (live.formaEntrega?.correios?.percentage < 0) {
          setDecrescimo(true);
          setFormaEntrega({
            ...live.formaEntrega,
            correios: {
              ...live.formaEntrega.correios,
              percentage: live.formaEntrega.correios.percentage * -1,
            },
          });
        }
      } else if (live.formaEntrega?.freteProprio) setTipoFrete("freteProprio");
      setNome(live.nome);
      setUrlYoutube(live.video);
      setPlayer(live.player);

      setDescricao(live.descricao);
      setThumb(live.thumbnail);
      onChangeDateValue([new Date(live.dataInicio), new Date(live.dataFim)]);
      setTimeStart(moment(live.dataInicio).format("HH:mm:ss"));
      setTimeEnd(moment(live.dataFim).format("HH:mm:ss"));
      if (live?.valorPedidoMinimo)
        setValorPedidoMinimo(
          parseFloat(live.valorPedidoMinimo / 100).toFixed(2)
        );
      if (
        live?.formaEntrega?.freteGratis?.valorMinimo &&
        !isNaN(live?.formaEntrega?.freteGratis?.valorMinimo)
      ) {
        setFormaEntrega({
          ...formaEntrega,
          freteGratis: {
            valorMinimo: parseFloat(
              live.formaEntrega.freteGratis.valorMinimo / 100
            ).toFixed(2),
          },
        });
        setTipoFrete("combinarVendedor");
      }

      if (live?.valorParcelaMinima && live?.valorPedidoMinimo)
        setCondicao("condicoesProprias");
      if (live?.paymentService === "pagarme") setCondicao("pagarme");
      if (live?.paymentService === "pagarme" && live?.condicoes)
        setPagarmeCondicoes(live.condicoes);
      if (live?.paymentService && live?.valorParcelaMinima)
        setParcelaMinima(parseFloat(live.valorParcelaMinima / 100).toFixed(2));
      if (live?.valorParcelaMinima && live.valorPedidoMinimo)
        setParcelaMinima(parseFloat(live.valorParcelaMinima / 100).toFixed(2));
      if (live?.condicoes?.length > 0) setCondicoes(live.condicoes);
      if (typeof live?.liveEstrangeira === "boolean")
        setLiveEstrangeira(live.liveEstrangeira);
      if (live?.unidadeMonetaria) setUnidadeMonetaria(live.unidadeMonetaria);
      setLadoCard(live.ladoCard);
      setRealtime(live.realtime);
      setAoVivo(live.aoVivo || false);
      setOrientation(live.vertical ? "vertical" : "horizontal");
      setCatalogService(live.catalog);
      if (live.gtmld) setGoogleTag(live.gtmld.replace("GTM-", ""));
      if (live.awinAdvertiserId) setAwinAdvertiserId(live.awinAdvertiserId);
      if (live.listaAssinatura) setListaAssinatura(live.listaAssinatura);
      if (live.listaAudiencia) setListaAudiencia(live.listaAudiencia);
      if (live.listaAudiencia) setTabelaPreco(live.tabelaPreco);
      if (live.splitRulesFrete) setSplitRulesFrete(live.splitRulesFrete);
      if (live.listaAudiencia && live.tabelaPreco)
        setLiveWithTabelaPreco(live.tabelaPreco);
      if (live.layoutCheckout) setLayoutCheckout(live.layoutCheckout);
      setAutoStart(live.autoStart);
      setStarted(live.started === true ? true : false);
      if (!live.started) handleCheckLiveStarted();
      if (live.formaPagto)
        setFormaPagto(
          live.formaPagto === "ambas"
            ? ["cc", "pix"]
            : live.formaPagto === "pix"
            ? ["pix"]
            : ["cc"]
        );
    }
  }, [live]);

  useEffect(() => setReal(realtime), [realtime, setReal]);

  useEffect(() => {
    if (!listaAudiencia && tabelaPreco) setTabelaPreco(false);
  }, [listaAudiencia, tabelaPreco]);

  useEffect(() => {
    setLiveWithAssinatura(listaAssinatura ? true : false);
  }, [listaAssinatura, setLiveWithAssinatura]);

  useEffect(() => {
    if (live.aoVivo && !aoVivo)
      Modal.warning({
        title: t("LIVE-TAB.Warning"),
        content: (
          <>
            {t("LIVE-TAB.WarningContentOne")}
            <strong> {t("LIVE-TAB.WarningContentTwo")}</strong>{" "}
            {t("LIVE-TAB.WarningContentThree")}
            <strong> {t("LIVE-TAB.WarningContentFour")}</strong>{" "}
            {t("LIVE-TAB.WarningContentFive")}
          </>
        ),
      });
  }, [aoVivo]);

  useEffect(() => {
    //Quando checkout é [vtex/magento/neomode, xml] o catálago DEVE ser também
    if (checkout) {
      if (checkout === "vtex") setCatalogService("vtex");
      else if (checkout === "magento") setCatalogService("magento");
      else if (checkout === "neomode") setCatalogService("neomode");
      else if (checkout === "externo") setCatalogService("interno");
    }
  }, [checkout]);

  JSON.safeStringify = (obj, indent = 2) => {
    let cache = [];
    const retVal = JSON.stringify(
      obj,
      (key, value) =>
        typeof value === "object" && value !== null
          ? cache.includes(value)
            ? undefined // Duplicate reference found, discard key
            : cache.push(value) && value // Store value in our collection
          : value,
      indent
    );
    cache = null;
    return retVal;
  };

  const handleOrientation = (value) => {
    setOrientation(value);
  };

  const handleSaveAudienceList = useCallback(
    (id) => {
      let liveId = id ? id : live.docId;
      managerService
        .saveAudienceList(liveId, listaAudiencia)
        .then(() => {
          message.success(t("LIVE-TAB.AudienceListSave"));
          setListaAudiencia(listaAudiencia);
        })
        .catch(() => message.error(t("LIVE-TAB.AudienceListSaveError")));
    },
    [listaAudiencia, live]
  );

  const checkPickup = () => {
    if (retirada) {
      let {
        zipcode,
        country,
        state,
        city,
        street,
        streetNumber,
        neighborhood,
        deadlineForTake,
      } = retirada;
      if (
        !zipcode ||
        !country ||
        !state ||
        !city ||
        !street ||
        !streetNumber ||
        !neighborhood ||
        !deadlineForTake
      ) {
        notification.info({
          key: "avisoRetirada",
          message: t("LIVE-TAB.Warning"),
          description: t("LIVE-TAB.PickupAddressRequired"),
          onClick: () => notification.close("avisoRetirada"),
          duration: 10,
        });
        setModalRetirada(true);
        return false;
      } else {
        return true;
      }
    } else {
      return true;
    }
  };

  const finish = async () => {
    if (!checkPickup()) {
      return;
    }

    if (
      !window.localStorage.getItem("removeWarningOnDemand") &&
      live.docId &&
      aoVivo
    ) {
      setOpenWarningModal(true);
    }

    setSaving(true);
    let correios = tipoFrete === "correios" ? formaEntrega.correios : false;
    if (correios && decrescimo) correios.percentage *= -1;

    let data = {
      nome,
      descricao,
      dataInicio: new Date(
        moment(dateValue[0]).format().substring(0, 10) + "T" + timeStart
      ).getTime(),
      dataFim: new Date(
        moment(dateValue[1]).format().substring(0, 10) + "T" + timeEnd
      ).getTime(),
      ladoCard,
      realtime,
      aoVivo,
      formaEntrega: !["vtex", "magento", "neomode"].includes(checkout)
        ? {
            correios: tipoFrete === "correios" ? formaEntrega.correios : false,
            freteProprio:
              tipoFrete === "freteProprio" ? formaEntrega.freteProprio : false,
            retirada,
            freteGratis: {
              valorMinimo: formaEntrega?.freteGratis?.valorMinimo
                ? parseInt(
                    parseFloat(formaEntrega?.freteGratis?.valorMinimo)
                      .toFixed(2)
                      .replace(".", "")
                  )
                : false,
            },
          }
        : FORMA_ENTREGA_MODEL,
      vertical: orientation === "vertical" ? true : false,
      listaAssinatura,
      listaAudiencia: listaAudiencia ? true : false,
      tabelaPreco: listaAudiencia && tabelaPreco ? true : false,
      catalog: catalogService,
      awinAdvertiserId: awinAdvertiserId ? awinAdvertiserId : false,
      gtmld: googleTag ? "GTM-" + googleTag : false,
      layoutCheckout,
      liveEstrangeira,
      unidadeMonetaria,
      valorPedidoMinimo: parseInt(
        parseFloat(valorPedidoMinimo).toFixed(2).replace(".", "")
      ),
      autoStart,
      started,
      player,
    };
    if (player === "aws") {
      data.player = "aws";
      data.video = "";
    } else if (player === "youtube") {
      data.player = "youtube";
      data.video = urlYoutube;
    }
    if (condicao === "condicoesProprias")
      data.valorParcelaMinima = parseInt(
        parseFloat(parcelaMinima || 0)
          .toFixed(2)
          .replace(".", "")
      );
    if (checkout === `interno`) {
      data.checkout = `interno`;
      if (condicao === "pagarme") {
        data.paymentService = "pagarme";
        data.checkout = "interno";
        let paymentWithCC = formaPagto.includes("cc");
        let paymentWithPix = formaPagto.includes("pix");
        if (!paymentWithCC) data.formaPagto = "pix";
        else {
          if (paymentWithPix) data.formaPagto = "ambas";
          else data.formaPagto = "cc";
          data.condicoes = pagarmeCondicoes;
          data.valorParcelaMinima = parseInt(
            parseFloat(parcelaMinima || 0)
              .toFixed(2)
              .replace(".", "")
          );
        }

        if (["correios", "freteProprio"].includes(tipoFrete))
          data.splitRulesFrete = splitRulesFrete;
      } else {
        data.condicoes = condicoes;
        data.paymentService = false;
      }
    } else if (checkout === "vtex") {
      data.checkout = `vtex`;
      data.condicoes = ["VTEX"];
    } else if (checkout === "neomode") {
      data.checkout = `neomode`;
      data.condicoes = ["NEOMODE"];
    } else if (checkout === "magento") {
      data.checkout = `magento`;
      data.condicoes = ["MAGENTO"];
    } else if (checkout === "externo") {
      data.checkout = `externo`;
      data.condicoes = ["EXTERNO"];
    }
    if (!live) {
      await convertImgToBase64URL(thumb, (base64) => {
        data.thumbnail = base64 ? base64.split("base64,")[1] : "";
        data.nameThumbnail = nameThumbnail;
        managerService
          .createLive(data)
          .then(({ id }) => {
            if (!live.listaAudiencia && listaAudiencia)
              handleSaveAudienceList(id);
            setLive({ ...data, thumbnail: base64, docId: id });
            history.push(`live-studio?l=${id}`);
            setSaving(false);
            notification.success({
              key: "aviso",
              message: t("LIVE-TAB.CreateLiveSuccess"),
              description: t("LIVE-TAB.CreateLiveSuccessDescription"),
              onClick: () => notification.close("aviso"),
              duration: 10,
            });
            next();
            setVivo(aoVivo);

            if (checkout === `vtex`)
              managerService.updateCredentials({
                appKey,
                appToken,
                accountName,
                environment,
                urlEcommerce,
                type: selectedType.toLowerCase(),
              });
            else if (checkout === "magento")
              managerService.updateCredentialsMagento({
                magentoURL,
                consumerKey,
                consumerSecret,
                accessToken,
                tokenSecret,
                sizeAttributeName,
              });
            else if (checkout === "neomode")
              neomodeService.updateCredentialsNeomode({
                clientId,
                clientSecret,
                scope,
              });
          })
          .catch(() => {
            notification.error({
              key: "aviso",
              message: t("LIVE-TAB.GeneralError"),
              description: t("LIVE-TAB.CreateLiveErrorDescription"),
              onClick: () => notification.close("aviso"),
              duration: 10,
            });
            setSaving(false);
          });
      });
    } else {
      let endRequest = (success) => {
        if (success) {
          setVivo(aoVivo);
          if (live.aoVivo && !aoVivo) {
            setLive(false);
            setLive({
              ...live,
              active: false,
              friendlyUrl: null,
              aoVivo: false,
            });
          } else setLive({ ...live, ...data });
          notification.success({
            key: "aviso",
            message: t("LIVE-TAB.LiveSavedSuccess"),
            description: t("LIVE-TAB.LiveSavedSuccessDescription"),
            onClick: () => notification.close("aviso"),
            duration: 10,
          });
        } else
          notification.error({
            key: "aviso",
            message: t("LIVE-TAB.LiveSavedError"),
            description: t("LIVE-TAB.LiveSavedErrorConditions"),
            onClick: () => notification.close("aviso"),
            duration: 10,
          });
        setSaving(false);
      };

      managerService
        .editLive(live.docId, data)
        .then(() => {
          if (listaAudiencia && listaAudiencia.length >= 1)
            handleSaveAudienceList();
          if (
            checkout === "vtex" &&
            (alreadyUseVtex.appKey !== appKey ||
              alreadyUseVtex.appToken !== appToken ||
              alreadyUseVtex.accountName !== accountName ||
              alreadyUseVtex.environment !== environment ||
              alreadyUseVtex.urlEcommerce !== urlEcommerce ||
              alreadyUseVtex.type !== selectedType.toLowerCase())
          )
            managerService
              .updateCredentials({
                appKey,
                appToken,
                accountName,
                environment,
                urlEcommerce,
                liveId: live.docId,
                type: selectedType.toLowerCase(),
              })
              .then(() =>
                setAlreadyUseVtex({
                  appKey,
                  appToken,
                  accountName,
                  environment,
                  urlEcommerce,
                })
              );
          if (
            checkout === "magento" &&
            (alreadyUseMagento.magentoURL !== magentoURL ||
              alreadyUseMagento.consumerKey !== consumerKey ||
              alreadyUseMagento.consumerSecret !== consumerSecret ||
              alreadyUseMagento.accessToken !== accessToken ||
              alreadyUseMagento.tokenSecret !== tokenSecret ||
              alreadyUseMagento.sizeAttributeName !== sizeAttributeName)
          )
            managerService
              .updateCredentialsMagento({
                magentoURL,
                consumerKey,
                consumerSecret,
                accessToken,
                tokenSecret,
                sizeAttributeName,
              })
              .then(() =>
                setAlreadyUseMagento({
                  magentoURL,
                  consumerKey,
                  consumerSecret,
                  accessToken,
                  tokenSecret,
                  sizeAttributeName,
                })
              );
          if (
            checkout === "neomode" &&
            (alreadyUseNeomode.clientId !== clientId ||
              alreadyUseNeomode.clientSecret !== clientSecret ||
              alreadyUseNeomode.scope !== scope)
          )
            neomodeService
              .updateCredentialsNeomode({
                clientId,
                clientSecret,
                scope,
              })
              .then(() =>
                setAlreadyUseNeomode({
                  clientId,
                  clientSecret,
                  scope,
                })
              );
          if (thumb !== live.thumbnail) {
            convertImgToBase64URL(thumb, (base64) => {
              managerService
                .editThumbnail(live.docId, {
                  thumbnail: base64 ? base64.split("base64,")[1] : "",
                  nameThumbnail,
                })
                .then(() => endRequest(true))
                .catch(() => endRequest());
            });
          } else endRequest(true);
        })
        .catch((e) => {
          if (player === "youtube") {
            notification.error({
              key: "aviso",
              message: e.response?.data?.message,
              onClick: () => notification.close("aviso"),
              duration: 10,
            });
          } else {
            endRequest();
          }
          setSaving(false);
        });
    }
  };

  const openWarningVtex = () => {
    if (!checkoutWarning && live.docId) {
      Modal.warning({
        title: t("LIVE-TAB.Warning"),
        content: <>{t("LIVE-TAB.WarningVTEXContent")}</>,
      });
      setCheckoutWarning(true);
    }
  };

  const openWarningMagento = () => {
    if (!checkoutWarning && live.docId) {
      Modal.warning({
        title: t("MAGENTO-CATALOG.Warning"),
        content: <>{t("MAGENTO-CATALOG.WarningMAGENTOContent")}</>,
      });
      setCheckoutWarning(true);
    }
  };

  const openWarningNeomode = () => {
    if (!checkoutWarning && live.docId) {
      Modal.warning({
        title: t("NEOMODE-CATALOG.Warning"),
        content: <>{t("NEOMODE-CATALOG.WarningContent")}</>,
      });
      setCheckoutWarning(true);
    }
  };

  const openWarningCatalog = () => {
    if (!catalogWarning && live.docId) {
      Modal.warning({
        title: t("LIVE-TAB.Warning"),
        content: <>{t("LIVE-TAB.WarningCatalogContent")}</>,
      });
      setCatalogWarning(true);
    }
  };

  const regex = /^[0-2][0-9]:[0-5][0-9]:[0-9]{2}$/;

  const checkFinishIsDisabled = () => {
    let isDisabled = false;
    if (!nome || !thumb || !descricao || !timeStart) isDisabled = true;
    else if (timeStart && timeStart.length < 8) isDisabled = true;
    else if (!timeEnd || (timeEnd && timeEnd.length < 8)) isDisabled = true;
    if (checkout === `interno`) {
      if (catalogService === "interno") {
        if (condicao === "livre" && (!condicoes || condicoes?.length === 0))
          isDisabled = true;
      } else if (catalogService === "vtex") {
        if (!urlEcommerce) isDisabled = true;
        if (!appKey || appKey?.length < 2) isDisabled = true;
        else if (!appToken || appToken?.length < 2) isDisabled = true;
        else if (!accountName || accountName?.length < 2) isDisabled = true;
        else if (!environment || environment?.length < 2) isDisabled = true;
      } else if (catalogService === "neomode") {
        if (!clientId || clientId?.length < 2) isDisabled = true;
        else if (!clientSecret || clientSecret?.length < 2) isDisabled = true;
        else if (!scope || scope?.length < 2) isDisabled = true;
      } else if (catalogService === "magento") {
        if (!magentoURL) isDisabled = true;
        else if (!consumerKey) isDisabled = true;
        else if (!consumerSecret) isDisabled = true;
        else if (!accessToken) isDisabled = true;
        else if (!tokenSecret) isDisabled = true;
      }
    } else if (checkout === "vtex") {
      if (!urlEcommerce) isDisabled = true;
      if (!appKey || appKey?.length < 2) isDisabled = true;
      else if (!appToken || appToken?.length < 2) isDisabled = true;
      else if (!accountName || accountName?.length < 2) isDisabled = true;
      else if (!environment || environment?.length < 2) isDisabled = true;
    } else if (checkout === "neomode") {
      if (!clientId || clientId?.length < 2) isDisabled = true;
      else if (!clientSecret || clientSecret?.length < 2) isDisabled = true;
      else if (!scope || scope?.length < 2) isDisabled = true;
    } else if (checkout === "magento") {
      if (!magentoURL) isDisabled = true;
      else if (!consumerKey) isDisabled = true;
      else if (!consumerSecret) isDisabled = true;
      else if (!accessToken) isDisabled = true;
      else if (!tokenSecret) isDisabled = true;
    }
    if (
      tipoFrete === "combinarVendedor" &&
      !formaEntrega.freteGratis?.valorMinimo
    )
      isDisabled = true;
    if (
      condicao === "condicoesProprias" &&
      (!condicoes || condicoes?.length === 0)
    )
      isDisabled = true;
    if (condicao === "pagarme") {
      if (formaPagto.length === 0) isDisabled = true;
      else if (formaPagto.includes("cc") && pagarmeCondicoes.length === 0)
        isDisabled = true;
    }
    if (awinAdvertiserId === true) isDisabled = true;
    if (!unidadeMonetaria) isDisabled = true;
    if (retirada) {
      let {
        zipcode,
        country,
        state,
        city,
        street,
        streetNumber,
        neighborhood,
        deadlineForTake,
      } = retirada;
      if (
        !zipcode ||
        !country ||
        !state ||
        !city ||
        !street ||
        !streetNumber ||
        !neighborhood ||
        !deadlineForTake
      )
        isDisabled = true;
    }
    return isDisabled;
  };

  const checkInvalidValues = (type) => {
    if (type === "nome" && !nome) setInvalidValues([...invalidValues, type]);
    else if (type === "descricao" && !descricao) {
      setInvalidValues([...invalidValues, type]);
    } else if (
      type === "timeLive" &&
      timeStart &&
      timeEnd &&
      new Date(
        moment(dateValue[1]).format().substring(0, 10) + "T" + timeEnd
      ).getTime() <=
        new Date(
          moment(dateValue[0]).format().substring(0, 10) + "T" + timeStart
        ).getTime()
    ) {
      setInvalidValues([...invalidValues, type]);
    } else if (type === "timeLive" && (!timeStart || !timeEnd)) {
      setInvalidValues([...invalidValues, type]);
    } else if (
      type === "timeLive" &&
      (!regex.test(timeStart) || !regex.test(timeEnd))
    ) {
      setInvalidValues([...invalidValues, type]);
    } else if (type === "newConditionFree" && condicoes.length === 0) {
      setInvalidValues([...invalidValues, type]);
    } else if (type === "parcelQuantity" && pagarmeCondicoes.length === 0) {
      setInvalidValues([...invalidValues, type]);
    } else if (
      type === "minFreeShippingValue" &&
      !formaEntrega.freteGratis.valorMinimo
    ) {
      setInvalidValues([...invalidValues, type]);
    } else if (
      type === "minParcelValue&Conditions" &&
      (!condicoes || condicoes.length === 0)
    ) {
      setInvalidValues([...invalidValues, type]);
    } else if (
      type === "vtexValues" &&
      (!appKey || !appToken || !environment || !accountName || !urlEcommerce)
    ) {
      setInvalidValues([...invalidValues, type]);
    } else if (
      type === "magentoValues" &&
      (!magentoURL ||
        !consumerKey ||
        !consumerSecret ||
        !accessToken ||
        !tokenSecret)
    ) {
      setInvalidValues([...invalidValues, type]);
    }
  };

  const checkVtexValues = () =>
    invalidValues.includes("vtexValues") &&
    appKey &&
    appToken &&
    accountName &&
    environment &&
    urlEcommerce;

  const checkNeomodeValues = () =>
    invalidValues.includes("neomodeValues") &&
    clientId &&
    clientSecret &&
    scope;

  const checkMagentoValues = () =>
    invalidValues.includes("magentoValues") &&
    magentoURL &&
    consumerKey &&
    consumerSecret &&
    accessToken &&
    tokenSecret;

  const handleStartLive = () => {
    let data = { ...live };
    data.started = !started;
    data.dataInicio = new Date().getTime();
    managerService
      .editLive(live.docId, data)
      .then(() => {
        setStarted(!started);
        setLive({ ...live, started: !started });
      })
      .catch(() => {
        notification.error({
          key: "aviso",
          message: t("LIVE-TAB.LiveSavedError"),
          description: t("LIVE-TAB.LiveSavedErrorDescription"),
          onClick: () => notification.close("aviso"),
          duration: 7,
        });
      });
  };

  const handleCheckLiveStarted = useCallback(() => {
    if (!started) {
      setRender((old) => !old);
      setTimeout(() => handleCheckLiveStarted(), 2000);
    }
  }, [started]);

  let startTimeOfLive = new Date(
    moment(dateValue[0]).format().substring(0, 10) + "T" + timeStart
  ).getTime();
  let nowTime = new Date().getTime();

  const clearFreeShippingValues = (isMyConditions) => {
    if (isMyConditions) {
      setParcelaMinima(0);
      setValorPedidoMinimo(0);
    } else {
      setFormaEntrega({ ...formaEntrega, freteGratis: { valorMinimo: false } });
    }
  };

  return (
    <div
      style={{ display: "flex", flexDirection: "column", alignItems: "center" }}
    >
      <div style={{ textAlign: "center", color: "gray" }}>
        {t("LIVE-TAB.FulfillInformationBellow")}{" "}
        {live?.docId ? t("LIVE-TAB.Edit") : t("LIVE-TAB.Create")}{" "}
        {t("LIVE-TAB.YourLive")}
        <br />
        {t("LIVE-TAB.FieldsWith")} <b> * </b> {t("LIVE-TAB.AreRequired")}
      </div>
      <div
        style={{
          display: "flex",
          flexWrap: "wrap",
          width: "100%",
          justifyContent: "center",
        }}
        className="fade-in"
      >
        <ModalAviso open={openWarningModal} setOpen={setOpenWarningModal} />

        <ModalSplits
          visible={splitsModal}
          onCancel={() => setSplitsModal(false)}
        />
        <Card
          title={
            <>
              <ScheduleOutlined style={{ marginRight: "10px" }} />
              {t("LIVE-TAB.LiveInformation")}
            </>
          }
          style={{ margin: "5px", maxWidth: "690px" }}
          className="shadow"
        >
          <s.Container>
            <section>
              <label>{t("LIVE-TAB.Name")} *</label>
              <Input
                value={nome}
                onChange={({ target: t }) => {
                  if (invalidValues.includes("nome"))
                    setInvalidValues(invalidValues.filter((x) => x !== "nome"));
                  setNome(t.value);
                }}
                onBlur={() => checkInvalidValues("nome")}
                style={{
                  border: invalidValues.includes("nome") && "1px solid red",
                }}
              />
              {invalidValues.includes("nome") && (
                <s.InvalidLabel className="fade-in">
                  {t("LIVE-TAB.NameRequired")}
                </s.InvalidLabel>
              )}
              <label>{t("LIVE-TAB.LiveImage")} *</label>
              <CropImage
                visible={cropImage}
                onFinish={({ objectUrl, name }) => {
                  setThumb(objectUrl);
                  setNameThumbnail(name);
                  setErrorThumb(false);
                  setCropImage(false);
                }}
                onCancel={() => setCropImage(false)}
                thumb={thumb}
              />
              <div
                onClick={() => setCropImage(true)}
                name="thumbnail"
                style={{ width: "282px" }}
              >
                {thumb && !errorThumb ? (
                  <img
                    style={s.imgStyle}
                    alt="thumbnail"
                    src={thumb}
                    onError={() => setErrorThumb(true)}
                  />
                ) : (
                  <Skeleton.Image
                    style={{
                      ...s.imgStyle,
                      border: "1px solid #d9d9d9",
                      width: "282px",
                    }}
                    active
                  ></Skeleton.Image>
                )}
              </div>

              <label>{t("LIVE-TAB.Description")} *</label>
              <TextArea
                style={{
                  maxHeight: "86px",
                  minHeight: "86px",
                  border:
                    invalidValues.includes("descricao") && "1px solid red",
                }}
                value={descricao}
                onBlur={() => checkInvalidValues("descricao")}
                onChange={({ target: t }) => {
                  if (invalidValues.includes("descricao"))
                    setInvalidValues(
                      invalidValues.filter((x) => x !== "descricao")
                    );
                  setDescricao(t.value);
                }}
              />
              {invalidValues.includes("descricao") && (
                <s.InvalidLabel className="fade-in">
                  {t("LIVE-TAB.DescriptionRequired")}
                </s.InvalidLabel>
              )}
            </section>
            <div className="wrapper" />
            <section>
              <s.SpaceBetween>
                <div>
                  <label
                    style={{
                      color:
                        timeStart && timeStart.length < 8 ? "red" : "black",
                    }}
                  >
                    {t("LIVE-TAB.StartTime")} *
                  </label>
                  <Input
                    onChange={({ target: t }) => {
                      if (invalidValues.includes("timeLive"))
                        setInvalidValues(
                          invalidValues.filter((x) => x !== "timeLive")
                        );
                      handleChangeTime(t.value, timeStart, setTimeStart);
                    }}
                    placeholder="00:00:00"
                    value={timeStart}
                    maxLength={8}
                    onBlur={() => checkInvalidValues("timeLive")}
                    style={{
                      border:
                        invalidValues.includes("timeLive") && "1px solid red",
                    }}
                  />
                </div>
                <div style={{ marginLeft: "55px" }}>
                  <label
                    style={{
                      color: timeEnd && timeEnd.length < 8 ? "red" : "black",
                    }}
                  >
                    {t("LIVE-TAB.EndTime")} *
                  </label>
                  <Input
                    onChange={({ target: t }) => {
                      if (invalidValues.includes("timeLive"))
                        setInvalidValues(
                          invalidValues.filter((x) => x !== "timeLive")
                        );
                      handleChangeTime(
                        t.value.replace(/[a-zA-Z]+/g, ":"),
                        timeEnd,
                        setTimeEnd
                      );
                    }}
                    placeholder="00:00:00"
                    value={timeEnd}
                    maxLength={8}
                    onBlur={() => checkInvalidValues("timeLive")}
                    style={{
                      border:
                        invalidValues.includes("timeLive") && "1px solid red",
                    }}
                  />
                </div>
              </s.SpaceBetween>
              {invalidValues.includes("timeLive") && (
                <s.InvalidLabel className="fade-in">
                  {t("LIVE-TAB.StartEndTimeRequired")}
                </s.InvalidLabel>
              )}
              <label>{t("LIVE-TAB.StartEndDate")} *</label>
              <Calendar
                onChange={(v) => {
                  if (invalidValues.includes("timeLive"))
                    setInvalidValues((list) =>
                      list.filter((value) => value !== "timeLive")
                    );
                  onChangeDateValue(v);
                }}
                value={dateValue}
                selectRange={true}
                // minDate={minDateCalendar}
                calendarType={"US"}
                minDetail={"month"}
                maxDetail={"month"}
                navigationLabel={({ label }) => (
                  <label style={{ color: "#828282" }}>
                    {label.split(" ")[0]}
                  </label>
                )}
                nextLabel={<RightOutlined />}
                prevLabel={<LeftOutlined />}
              />
            </section>
          </s.Container>
        </Card>
        <Card
          title={
            <>
              <FieldTimeOutlined style={{ marginRight: "10px" }} />
              {t("LIVE-TAB.StartOfLive")}
            </>
          }
          style={{ margin: "5px", maxWidth: "340px" }}
          className="shadow"
        >
          <legend style={{ fontSize: "12px", color: "gray" }}>
            {t("LIVE-TAB.StartLiveAutomaticallyDescription")}
          </legend>
          <label>{t("LIVE-TAB.StartLiveAutomatically")}</label>
          <Switch
            checked={autoStart}
            onChange={() => setAutoStart(!autoStart)}
            style={{ width: 30, marginBottom: "10px", display: "grid" }}
          />
          {!autoStart && (
            <>
              <legend style={{ fontSize: "12px", color: "gray" }}>
                {t("LIVE-TAB.StartLiveAutomaticallyButtonLegend")}
              </legend>
              <Button
                type={started ? "danger" : "primary"}
                disabled={
                  (!started && !live.active) || nowTime <= startTimeOfLive
                }
                onClick={handleStartLive}
              >
                {started
                  ? live.active
                    ? t("LIVE-TAB.CancelStart")
                    : t("LIVE-TAB.LiveStarted")
                  : t("LIVE-TAB.StartLive")}
              </Button>
              <s.CountDownContainer>
                <Countdown
                  value={moment(live?.dataInicio)}
                  format="DD:HH:mm:ss"
                />
              </s.CountDownContainer>
            </>
          )}
        </Card>
        <Card
          title={
            <>
              <ScheduleOutlined style={{ marginRight: "10px" }} />
              {t("LIVE-TAB.ForeignLive")}
            </>
          }
          style={{ margin: "5px", maxWidth: "340px" }}
          className="shadow"
        >
          <legend style={{ fontSize: "12px", color: "gray" }}>
            {t("LIVE-TAB.ForeignLiveDescription")}
          </legend>
          <label>{t("LIVE-TAB.LiveOutsideBrasil")}</label>
          <Switch
            checked={liveEstrangeira}
            onChange={() => setLiveEstrangeira(!liveEstrangeira)}
            style={{ width: 30, marginBottom: "10px", display: "grid" }}
          />
          <label> {t("LIVE-TAB.CurrencyUnitDescription")} *</label> <br />
          <Select
            defaultValue={unidadeMonetaria}
            onChange={(e) => setUnidadeMonetaria(e)}
            value={unidadeMonetaria}
            style={{ width: "100px", marginTop: "5px" }}
          >
            {onlyMonetaryTypes.map((type, index) => (
              <Option key={index} value={type}>
                {type}
              </Option>
            ))}
          </Select>
        </Card>
        <AudienceCard
          liveId={live.docId}
          setListaAudiencia={setListaAudiencia}
          listaAudiencia={listaAudiencia}
          tabelaPreco={tabelaPreco}
          setTabelaPreco={setTabelaPreco}
          awinAdvertiserId={awinAdvertiserId}
          setAwinAdvertiserId={setAwinAdvertiserId}
        />
        <Card
          title={
            <>
              <MenuFoldOutlined style={{ marginRight: "10px" }} />
              {t("LIVE-TAB.MenuStyle")}
            </>
          }
          style={{ margin: "5px", width: "340px" }}
          className="shadow"
        >
          <label>{t("LIVE-TAB.MenuStyle")} *</label>
          <legend style={{ fontSize: "12px", color: "gray" }}>
            {t("LIVE-TAB.MenuStyleDescription")}
          </legend>
          <Radio.Group
            required
            style={{ display: "grid", marginBottom: "5px" }}
            value={layoutCheckout}
            onChange={({ target: t }) => {
              if (t.value === "ecommerce" && condicao === "condicoesProprias") {
                setCondicao("livre");
                clearFreeShippingValues(true);
              }
              if (t.value === "ecommerce" && tipoFrete === "combinarVendedor") {
                setTipoFrete("nenhum");
                clearFreeShippingValues();
              }
              setLayoutCheckout(t.value);
            }}
          >
            <Radio value={"ecommerce"}>B2C</Radio>
            <Radio value={"padrao"}>B2B</Radio>
          </Radio.Group>

          {layoutCheckout === "ecommerce" ? (
            <div
              style={{
                border: "1px solid #e9e9e9",
                display: "grid",
                padding: "10px",
                opacity: 0.8,
              }}
            >
              <label>{t("LIVE-TAB.Size")}:</label>
              <Select defaultValue="P">
                {["P", "M", "G", "GG"].map((n) => (
                  <Option key={`e-${n}`}>{n}</Option>
                ))}
              </Select>
              <label>{t("LIVE-TAB.Quantity")}:</label>
              <Select defaultValue={1}>
                {[1, 2, 3, 4, 5].map((n) => (
                  <Option key={`input-example-${n}`}>{n}</Option>
                ))}
              </Select>
            </div>
          ) : (
            <Scrollbars
              style={{ height: 130, border: "1px solid #e9e9e9", opacity: 0.9 }}
            >
              {["P", "M", "G", "GG"].map((size) => (
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                    borderBottom: "1px solid #e7e7e7",
                    padding: "10px",
                  }}
                >
                  <label>{size}</label>
                  <Select defaultValue={1} style={{ width: 200 }}>
                    {[1, 2, 3, 4, 5].map((n) => (
                      <Option key={`input-example-${n}`}>{n}</Option>
                    ))}
                  </Select>
                </div>
              ))}
            </Scrollbars>
          )}
          <legend style={{ fontSize: "12px", color: "gray", marginTop: 10 }}>
            {t("LIVE-TAB.OnlyOpenGrid")}
          </legend>
        </Card>
        <Card
          title={
            <>
              <GlobalOutlined style={{ marginRight: "10px" }} />
              {t("LIVE-TAB.Transmission")}
            </>
          }
          style={{ margin: "5px", width: "340px" }}
          className="shadow"
        >
          <label>{t("LIVE-TAB.CardLocalization")} *</label>
          <Radio.Group
            required
            style={{ display: "grid", marginBottom: "10px" }}
            value={ladoCard}
            onChange={({ target: t }) => setLadoCard(t.value)}
          >
            <Radio value={"esquerda"}>{t("LIVE-TAB.Left")}</Radio>
            <Radio value={"direita"}>{t("LIVE-TAB.Right")}</Radio>
          </Radio.Group>
          <label>{t("LIVE-TAB.Realtime")}</label>
          <legend style={{ fontSize: "12px", color: "gray" }}>
            {t("LIVE-TAB.RealtimeDescription")}
          </legend>
          <Switch
            checked={realtime}
            onChange={() => setRealtime(!realtime)}
            style={{ width: 30, marginBottom: "10px", display: "grid" }}
          />
          <label>{t("LIVE-TAB.LiveTransmission")}</label>
          <legend style={{ fontSize: "12px", color: "gray" }}>
            {t("LIVE-TAB.LiveTransmissionDescription")}
          </legend>
          <Switch
            checked={aoVivo}
            onChange={() => setAoVivo(!aoVivo)}
            style={{ width: 30, marginBottom: "10px", display: "grid" }}
          />
          {aoVivo && (
            <>
              <label>{t("Player de Transmissão")}</label>
              <Radio.Group
                style={{ display: "grid", marginBottom: "10px" }}
                value={player}
                onChange={({ target: t }) => {
                  setPlayer(t.value);
                }}
              >
                {user?.player?.includes("aws") && (
                  <Radio value={"aws"}>AWS</Radio>
                )}
                {user?.player?.includes("youtube") && (
                  <Radio value={"youtube"}>YouTube</Radio>
                )}
              </Radio.Group>
              {player === "youtube" && (
                <Input
                  placeholder="URL de Transmissão YouTube"
                  value={urlYoutube}
                  onChange={({ target: { value } }) => {
                    setUrlYoutube(value);
                  }}
                ></Input>
              )}
            </>
          )}
          <article style={{ marginTop: "10px" }}>
            <label>{t("LIVE-TAB.LiveOrientation")} *</label>
            <OrientationContainer
              orientation={orientation}
              handleOrientation={handleOrientation}
            />
          </article>
        </Card>
        <Card
          title={
            <>
              <ShoppingCartOutlined style={{ marginRight: "10px" }} />
              {t("LIVE-TAB.Checkout")}
            </>
          }
          style={{ margin: "5px", width: "340px" }}
          className="shadow"
        >
          <label>{t("LIVE-TAB.SubscriptionCheckout")}</label>
          <legend style={{ fontSize: "12px", color: "gray" }}>
            {t("LIVE-TAB.SubscriptionCheckoutDescription")}
          </legend>
          <div
            style={{ display: "flex", alignItems: "center", height: "30px" }}
          >
            <Switch
              checked={listaAssinatura}
              onChange={() =>
                setListaAssinatura(
                  listaAssinatura ? false : live.listaAssinatura || []
                )
              }
              style={{ width: 30 }}
            />
            {listaAssinatura && (
              <>
                <Button
                  size="small"
                  style={{ marginLeft: "10px" }}
                  onClick={() => setModalAssinantes(true)}
                >
                  <UsergroupAddOutlined />
                  {t("LIVE-TAB.SubscriberList")}
                </Button>
                <ModalAssinantes
                  visible={modalAssinantes}
                  onOk={(newList) => {
                    setListaAssinatura(newList);
                    setModalAssinantes(false);
                  }}
                  listaAssinatura={listaAssinatura}
                  onCancel={() => setModalAssinantes(false)}
                />
              </>
            )}
          </div>

          <label>{t("LIVE-TAB.TypeCheckout")} *</label>
          <Radio.Group
            required
            style={{ display: "grid", marginBottom: "10px" }}
            value={checkout}
            onChange={({ target: t }) => {
              if (t.value !== "interno" && condicao === "condicoesProprias") {
                setCondicao("livre");
                clearFreeShippingValues(true);
              }
              if (t.value !== "interno" && tipoFrete === "combinarVendedor") {
                setTipoFrete("nenhum");
                clearFreeShippingValues();
              }
              if (t.value === "vtex") setVtexKeys();
              if (t.value === "magento") setMagentoKeys();
              setCheckout(t.value);
            }}
          >
            <Radio value={"interno"}>{t("LIVE-TAB.TypeCheckoutIntern")}</Radio>
            <Radio value={"externo"}>
              {t("LIVE-TAB.TypeCheckoutExternal")}
            </Radio>
            <Radio value={"vtex"}>{t("LIVE-TAB.TypeCheckoutVTEX")}</Radio>
            <Radio value={"magento"}>{t("LIVE-TAB.TypeCheckoutMAGENTO")}</Radio>
            <Radio value={"neomode"}>{t("LIVE-TAB.TypeCheckoutNEOMODE")}</Radio>
          </Radio.Group>
          {checkout === `interno` ? (
            <>
              <label>{t("LIVE-TAB.PaymentConditions")} *</label>
              <Radio.Group
                required
                style={{ display: "grid" }}
                value={condicao}
                onChange={({ target: t }) => {
                  if (
                    t.value !== "condicoesProprias" &&
                    condicao === "condicoesProprias"
                  )
                    clearFreeShippingValues(true);
                  setCondicao(t.value);
                }}
              >
                <Radio value={"livre"}>{t("LIVE-TAB.MyConditions")}</Radio>
                <Radio value={"pagarme"}>{t("LIVE-TAB.PlatformPayment")}</Radio>
              </Radio.Group>
              {condicao === "livre" ? (
                <div style={{ marginTop: "5px" }}>
                  <Search
                    placeholder={t("LIVE-TAB.NewCondition")}
                    onBlur={() => checkInvalidValues("newConditionFree")}
                    style={{
                      border:
                        invalidValues.includes("newConditionFree") &&
                        "1px solid red",
                    }}
                    enterButton={<PlusOutlined />}
                    onSearch={(v) => {
                      if (invalidValues.includes("newConditionFree"))
                        setInvalidValues(
                          invalidValues.filter((x) => x !== "newConditionFree")
                        );
                      if (
                        v &&
                        (!condicoes || (condicoes && !condicoes.includes(v)))
                      )
                        setCondicoes([...condicoes, v]);
                    }}
                    required
                  />
                  {invalidValues.includes("newConditionFree") && (
                    <s.InvalidLabel className="fade-in">
                      {t("LIVE-TAB.NewConditionRequired")}
                    </s.InvalidLabel>
                  )}
                  <div
                    style={{
                      display: "flex",
                      flexWrap: "wrap",
                      marginTop: "10px",
                    }}
                  >
                    {condicoes &&
                      condicoes.map((s, i) => (
                        <Tag
                          key={`${s}-${i}`}
                          closable
                          onClose={() =>
                            setCondicoes(condicoes.filter((e) => e !== s))
                          }
                          style={{ margin: "0 5px 5px 0" }}
                        >
                          {s}
                        </Tag>
                      ))}
                  </div>
                </div>
              ) : condicao === "pagarme" ? (
                <div style={{ display: "flex", flexDirection: "column" }}>
                  <div style={{ marginBottom: "10px" }}>
                    <label style={{ fontSize: "12px", color: "gray" }}>
                      {t("LIVE-TAB.PagarmeDescriptionOne")}{" "}
                      <strong>{t("LIVE-TAB.Pagarme")}</strong>{" "}
                      {t("LIVE-TAB.PagarmeDescriptionTwo")}
                      <a onClick={() => setSplitsModal(true)}>
                        {" "}
                        {t("LIVE-TAB.PagarmeDescriptionThree")}
                      </a>
                      .
                    </label>
                  </div>
                  <Checkbox
                    checked={formaPagto.includes("pix")}
                    onChange={() =>
                      formaPagto.includes("pix")
                        ? setFormaPagto((oldValues) =>
                            oldValues.filter((value) => value !== "pix")
                          )
                        : setFormaPagto((oldValues) => [...oldValues, "pix"])
                    }
                  >
                    Pix
                  </Checkbox>
                  <Checkbox
                    style={{ marginLeft: 0 }}
                    checked={formaPagto.includes("cc")}
                    onChange={() =>
                      formaPagto.includes("cc")
                        ? setFormaPagto((oldValues) =>
                            oldValues.filter((value) => value !== "cc")
                          )
                        : setFormaPagto((oldValues) => [...oldValues, "cc"])
                    }
                  >
                    {t("CART.CreditCard")}
                  </Checkbox>
                  {formaPagto.includes("cc") && (
                    <>
                      <label>{t("LIVE-TAB.SelectParcels")}</label>
                      <Select
                        value={pagarmeCondicoes}
                        placeholder={t("LIVE-TAB.Select")}
                        onChange={(value) => {
                          if (invalidValues.includes("parcelQuantity"))
                            setInvalidValues(
                              invalidValues.filter((x, i) =>
                                i === 0 ? true : x !== "parcelQuantity"
                              )
                            );
                          setPagarmeCondicoes(value);
                        }}
                        mode="multiple"
                        onBlur={() => checkInvalidValues("parcelQuantity")}
                        style={{
                          border:
                            invalidValues.includes("parcelQuantity") &&
                            "1px solid red",
                          width: "100%",
                        }}
                      >
                        {[1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12].map((val) => (
                          <Option key={val} value={val} title={false}>
                            {val}
                          </Option>
                        ))}
                      </Select>
                      {invalidValues.includes("parcelQuantity") && (
                        <s.InvalidLabel className="fade-in">
                          {t("LIVE-TAB.ParcelRequired")}
                        </s.InvalidLabel>
                      )}
                      <label style={{ marginTop: 10 }}>
                        {t("LIVE-TAB.ParcelMinimumValue")}
                      </label>
                      <Input
                        type="number"
                        placeholder="00,00"
                        min={0}
                        onPaste={(e) => preventInvalidNumberOnPaste(e)}
                        onKeyDown={(e) => preventInvalidNumberOnKeyDown(e)}
                        onChange={({ target: { value: v } }) => {
                          if (v >= 0 && !isNaN(v))
                            setParcelaMinima(parseFloat(v));
                        }}
                        value={parcelaMinima}
                        disabled={
                          !pagarmeCondicoes || pagarmeCondicoes.length === 0
                        }
                      />
                    </>
                  )}
                  {formaPagto.length === 0 && (
                    <s.InvalidLabel className="fade-in">
                      {t("LIVE-TAB.ChooseOnePaymentMethod")} <br />
                    </s.InvalidLabel>
                  )}
                </div>
              ) : (
                <label style={{ color: "gray", fontSize: 12 }}>
                  {t("LIVE-TAB.MyConditionsWithMinParcelValueDescription")}
                </label>
              )}
            </>
          ) : checkout === "vtex" ? (
            <>
              <label>{t("LIVE-TAB.KeysToIntegrate")} *</label>
              <Input
                placeholder="URL ECOMMERCE"
                style={{ marginTop: "5px" }}
                value={urlEcommerce}
                onBlur={() => checkInvalidValues("vtexValues")}
                onChange={({ target: t }) => {
                  setUrlEcommerce(t.value);
                  openWarningVtex();
                  if (checkVtexValues())
                    setInvalidValues(
                      invalidValues.filter((x) => x !== "vtexValues")
                    );
                }}
              />
              <Input
                placeholder="APP KEY"
                style={{ marginTop: "5px" }}
                value={appKey}
                onBlur={() => checkInvalidValues("vtexValues")}
                onChange={({ target: t }) => {
                  setAppKey(t.value);
                  openWarningVtex();
                  if (checkVtexValues())
                    setInvalidValues(
                      invalidValues.filter((x) => x !== "vtexValues")
                    );
                }}
              />
              <Input
                placeholder="APP TOKEN"
                style={{ marginTop: "5px" }}
                value={appToken}
                onBlur={() => checkInvalidValues("vtexValues")}
                onChange={({ target: t }) => {
                  setAppToken(t.value);
                  openWarningVtex();
                  if (checkVtexValues())
                    setInvalidValues(
                      invalidValues.filter((x) => x !== "vtexValues")
                    );
                }}
              />
              <Input
                placeholder="ACCOUNT NAME"
                style={{ marginTop: "5px" }}
                value={accountName}
                onBlur={() => checkInvalidValues("vtexValues")}
                onChange={({ target: t }) => {
                  setAccountName(t.value);
                  openWarningVtex();
                  if (checkVtexValues())
                    setInvalidValues(
                      invalidValues.filter((x) => x !== "vtexValues")
                    );
                }}
              />
              <Input
                placeholder="ENVIRONMENT"
                style={{ marginTop: "5px" }}
                value={environment}
                onBlur={() => checkInvalidValues("vtexValues")}
                onChange={({ target: t }) => {
                  setEnvironment(t.value);
                  openWarningVtex();
                  if (checkVtexValues())
                    setInvalidValues(
                      invalidValues.filter((x) => x !== "vtexValues")
                    );
                }}
              />

              {invalidValues.includes("vtexValues") && (
                <s.InvalidLabel className="fade-in">
                  {t("LIVE-TAB.AllFieldsRequired")} <br />
                </s.InvalidLabel>
              )}
              <label style={{ marginTop: 10 }}>
                {t("LIVE-TAB.MarketType")}
              </label>
              <Select
                value={selectedType}
                onChange={(v) => setSelectedType(v)}
                style={{ width: "203px" }}
              >
                {optionsType.map((type) => (
                  <Option value={type} key={type}>
                    {type}
                  </Option>
                ))}
              </Select>
            </>
          ) : checkout === "neomode" ? (
            <>
              <label>{t("LIVE-TAB.KeysToIntegrate")} *</label>
              <Input
                placeholder="CLIENT ID"
                style={{ marginTop: "5px" }}
                value={clientId}
                onBlur={() => checkInvalidValues("neomodeValues")}
                onChange={({ target: t }) => {
                  setClientId(t.value);
                  openWarningNeomode();
                  if (checkNeomodeValues())
                    setInvalidValues(
                      invalidValues.filter((x) => x !== "neomodeValues")
                    );
                }}
              />
              <Input
                placeholder="CLIENT SECRET"
                style={{ marginTop: "5px" }}
                value={clientSecret}
                onBlur={() => checkInvalidValues("neomodeValues")}
                onChange={({ target: t }) => {
                  setClientSecret(t.value);
                  openWarningNeomode();
                  if (checkNeomodeValues())
                    setInvalidValues(
                      invalidValues.filter((x) => x !== "neomodeValues")
                    );
                }}
              />
              <Input
                placeholder="SCOPE"
                style={{ marginTop: "5px" }}
                value={scope}
                onBlur={() => checkInvalidValues("neomodeValues")}
                onChange={({ target: t }) => {
                  setScope(t.value);
                  openWarningNeomode();
                  if (checkNeomodeValues())
                    setInvalidValues(
                      invalidValues.filter((x) => x !== "neomodeValues")
                    );
                }}
              />
              {invalidValues.includes("neomodeValues") && (
                <s.InvalidLabel className="fade-in">
                  {t("LIVE-TAB.AllFieldsRequired")} <br />
                </s.InvalidLabel>
              )}
            </>
          ) : (
            checkout === "magento" && (
              <>
                <label>{t("LIVE-TAB.KeysToIntegrate")} *</label>
                <Input
                  placeholder="Magento url"
                  style={{ marginTop: "5px" }}
                  value={magentoURL}
                  onBlur={() => checkInvalidValues("magentoValues")}
                  onChange={({ target: t }) => {
                    openWarningMagento();
                    setMagentoURL(t.value);
                    if (checkMagentoValues())
                      setInvalidValues(
                        invalidValues.filter((x) => x !== "magentoValues")
                      );
                  }}
                />
                <Input
                  placeholder="Consumer key"
                  style={{ marginTop: "5px" }}
                  value={consumerKey}
                  onBlur={() => checkInvalidValues("magentoValues")}
                  onChange={({ target: t }) => {
                    openWarningMagento();
                    setConsumerKey(t.value);
                    if (checkMagentoValues())
                      setInvalidValues(
                        invalidValues.filter((x) => x !== "magentoValues")
                      );
                  }}
                />
                <Input
                  placeholder="Consumer secret"
                  style={{ marginTop: "5px" }}
                  value={consumerSecret}
                  onBlur={() => checkInvalidValues("magentoValues")}
                  onChange={({ target: t }) => {
                    openWarningMagento();
                    setConsumerSecret(t.value);
                    if (checkMagentoValues())
                      setInvalidValues(
                        invalidValues.filter((x) => x !== "magentoValues")
                      );
                  }}
                />
                <Input
                  placeholder="Access token"
                  style={{ marginTop: "5px" }}
                  value={accessToken}
                  onBlur={() => checkInvalidValues("magentoValues")}
                  onChange={({ target: t }) => {
                    openWarningMagento();
                    setAccessToken(t.value);
                    if (checkMagentoValues())
                      setInvalidValues(
                        invalidValues.filter((x) => x !== "magentoValues")
                      );
                  }}
                />
                <Input
                  placeholder="Token secret"
                  style={{ marginTop: "5px" }}
                  value={tokenSecret}
                  onBlur={() => checkInvalidValues("magentoValues")}
                  onChange={({ target: t }) => {
                    openWarningMagento();
                    setTokenSecret(t.value);
                    if (checkMagentoValues())
                      setInvalidValues(
                        invalidValues.filter((x) => x !== "magentoValues")
                      );
                  }}
                />
                <Input
                  placeholder={`Size attribute name (${t(
                    "LIVE-TAB.Optional"
                  )})`}
                  style={{ marginTop: "5px" }}
                  value={sizeAttributeName}
                  onChange={({ target: t }) => {
                    openWarningMagento();
                    setSizeAttributeName(t.value);
                  }}
                />
                {invalidValues.includes("magentoValues") && (
                  <s.InvalidLabel className="fade-in">
                    {t("LIVE-TAB.SomeFieldsRequired")} <br />
                  </s.InvalidLabel>
                )}
              </>
            )
          )}
        </Card>
        <Card
          title={
            <>
              <UnorderedListOutlined style={{ marginRight: "10px" }} />
              {t("LIVE-TAB.Catalog")}
            </>
          }
          style={{ margin: "5px", width: "340px" }}
          className="shadow"
        >
          <label>{t("LIVE-TAB.ProductsCatalog")}</label>
          <legend style={{ fontSize: "12px", color: "gray" }}>
            {t("LIVE-TAB.CatalogDescription")}
          </legend>

          <label>{t("LIVE-TAB.ServiceCatalog")} *</label>
          <Radio.Group
            required
            style={{ display: "grid", marginBottom: "10px" }}
            value={catalogService}
            onChange={({ target: t }) => {
              setCatalogService(t.value);
              openWarningCatalog();
              if (t.value === "vtex") setVtexKeys();
              if (t.value === "magento") setMagentoKeys();
            }}
          >
            <Radio
              value={"interno"}
              disabled={["vtex", "magento", "neomode"].includes(checkout)}
            >
              {t("LIVE-TAB.InternCatalog")}
            </Radio>
            <Radio
              value={"vtex"}
              disabled={["magento", "neomode", "externo"].includes(checkout)}
            >
              {t("LIVE-TAB.VTEXCatalog")}
            </Radio>
            <Radio
              value={"magento"}
              disabled={["vtex", "neomode", "externo"].includes(checkout)}
            >
              {t("LIVE-TAB.MAGENTOCatalog")}
            </Radio>
            <Radio
              value={"neomode"}
              disabled={["magento", "vtex", "externo"].includes(checkout)}
            >
              {t("LIVE-TAB.NEOMODECatalog")}
            </Radio>
          </Radio.Group>
          {checkout === "interno" ? (
            catalogService === "vtex" ? (
              <>
                <label>{t("LIVE-TAB.KeysToIntegrate")} *</label>
                <Input
                  placeholder="URL ECOMMERCE"
                  style={{ marginTop: "5px" }}
                  value={urlEcommerce}
                  onBlur={() => checkInvalidValues("vtexValues")}
                  onChange={({ target: t }) => {
                    setUrlEcommerce(t.value);
                    openWarningVtex();
                    if (checkVtexValues())
                      setInvalidValues(
                        invalidValues.filter((x) => x !== "vtexValues")
                      );
                  }}
                />
                <Input
                  placeholder="APP KEY"
                  style={{ marginTop: "5px" }}
                  value={appKey}
                  onBlur={() => checkInvalidValues("vtexValues")}
                  onChange={({ target: t }) => {
                    setAppKey(t.value);
                    openWarningVtex();
                    if (checkVtexValues())
                      setInvalidValues(
                        invalidValues.filter((x) => x !== "vtexValues")
                      );
                  }}
                />
                <Input
                  placeholder="APP TOKEN"
                  style={{ marginTop: "5px" }}
                  value={appToken}
                  onBlur={() => checkInvalidValues("vtexValues")}
                  onChange={({ target: t }) => {
                    setAppToken(t.value);
                    openWarningVtex();
                    if (checkVtexValues())
                      setInvalidValues(
                        invalidValues.filter((x) => x !== "vtexValues")
                      );
                  }}
                />
                <Input
                  placeholder="ACCOUNT NAME"
                  style={{ marginTop: "5px" }}
                  value={accountName}
                  onBlur={() => checkInvalidValues("vtexValues")}
                  onChange={({ target: t }) => {
                    setAccountName(t.value);
                    openWarningVtex();
                    if (checkVtexValues())
                      setInvalidValues(
                        invalidValues.filter((x) => x !== "vtexValues")
                      );
                  }}
                />
                <Input
                  placeholder="ENVIRONMENT"
                  style={{ marginTop: "5px" }}
                  value={environment}
                  onBlur={() => checkInvalidValues("vtexValues")}
                  onChange={({ target: t }) => {
                    setEnvironment(t.value);
                    openWarningVtex();
                    if (checkVtexValues())
                      setInvalidValues(
                        invalidValues.filter((x) => x !== "vtexValues")
                      );
                  }}
                />
                {invalidValues.includes("vtexoValues") && (
                  <s.InvalidLabel className="fade-in">
                    {t("LIVE-TAB.AllFieldsRequired")} <br />
                  </s.InvalidLabel>
                )}
                <label style={{ marginTop: 10 }}>
                  {t("LIVE-TAB.MarketType")}
                </label>
                <Select
                  value={selectedType}
                  onChange={(v) => setSelectedType(v)}
                  style={{ width: "203px" }}
                >
                  {optionsType.map((type) => (
                    <Option value={type} key={type}>
                      {type}
                    </Option>
                  ))}
                </Select>
              </>
            ) : catalogService === "neomode" ? (
              <>
                <label>{t("LIVE-TAB.KeysToIntegrate")} *</label>
                <Input
                  placeholder="CLIENT ID"
                  style={{ marginTop: "5px" }}
                  value={clientId}
                  onBlur={() => checkInvalidValues("neomodeValues")}
                  onChange={({ target: t }) => {
                    setClientId(t.value);
                    openWarningNeomode();
                    if (checkNeomodeValues())
                      setInvalidValues(
                        invalidValues.filter((x) => x !== "neomodeValues")
                      );
                  }}
                />
                <Input
                  placeholder="CLIENT SECRET"
                  style={{ marginTop: "5px" }}
                  value={clientSecret}
                  onBlur={() => checkInvalidValues("neomodeValues")}
                  onChange={({ target: t }) => {
                    setClientSecret(t.value);
                    openWarningNeomode();
                    if (checkNeomodeValues())
                      setInvalidValues(
                        invalidValues.filter((x) => x !== "neomodeValues")
                      );
                  }}
                />
                <Input
                  placeholder="SCOPE"
                  style={{ marginTop: "5px" }}
                  value={scope}
                  onBlur={() => checkInvalidValues("neomodeValues")}
                  onChange={({ target: t }) => {
                    setScope(t.value);
                    openWarningNeomode();
                    if (checkNeomodeValues())
                      setInvalidValues(
                        invalidValues.filter((x) => x !== "neomodeValues")
                      );
                  }}
                />
                {invalidValues.includes("neomodeValues") && (
                  <s.InvalidLabel className="fade-in">
                    {t("LIVE-TAB.AllFieldsRequired")} <br />
                  </s.InvalidLabel>
                )}
              </>
            ) : (
              catalogService === "magento" && (
                <>
                  <label>{t("LIVE-TAB.KeysToIntegrate")} *</label>
                  <Input
                    placeholder="Magento url"
                    style={{ marginTop: "5px" }}
                    value={magentoURL}
                    onBlur={() => checkInvalidValues("magentoValues")}
                    onChange={({ target: t }) => {
                      openWarningMagento();
                      setMagentoURL(t.value);
                      if (checkMagentoValues())
                        setInvalidValues(
                          invalidValues.filter((x) => x !== "magentoValues")
                        );
                    }}
                  />
                  <Input
                    placeholder="Consumer key"
                    style={{ marginTop: "5px" }}
                    value={consumerKey}
                    onBlur={() => checkInvalidValues("magentoValues")}
                    onChange={({ target: t }) => {
                      openWarningMagento();
                      setConsumerKey(t.value);
                      if (checkMagentoValues())
                        setInvalidValues(
                          invalidValues.filter((x) => x !== "magentoValues")
                        );
                    }}
                  />
                  <Input
                    placeholder="Consumer secret"
                    style={{ marginTop: "5px" }}
                    value={consumerSecret}
                    onBlur={() => checkInvalidValues("magentoValues")}
                    onChange={({ target: t }) => {
                      openWarningMagento();
                      setConsumerSecret(t.value);
                      if (checkMagentoValues())
                        setInvalidValues(
                          invalidValues.filter((x) => x !== "magentoValues")
                        );
                    }}
                  />
                  <Input
                    placeholder="Access token"
                    style={{ marginTop: "5px" }}
                    value={accessToken}
                    onBlur={() => checkInvalidValues("magentoValues")}
                    onChange={({ target: t }) => {
                      openWarningMagento();
                      setAccessToken(t.value);
                      if (checkMagentoValues())
                        setInvalidValues(
                          invalidValues.filter((x) => x !== "magentoValues")
                        );
                    }}
                  />
                  <Input
                    placeholder="Token secret"
                    style={{ marginTop: "5px" }}
                    value={tokenSecret}
                    onBlur={() => checkInvalidValues("magentoValues")}
                    onChange={({ target: t }) => {
                      openWarningMagento();
                      setTokenSecret(t.value);
                      if (checkMagentoValues())
                        setInvalidValues(
                          invalidValues.filter((x) => x !== "magentoValues")
                        );
                    }}
                  />
                  <Input
                    placeholder={`Size attribute name (${t(
                      "LIVE-TAB.Optional"
                    )})`}
                    style={{ marginTop: "5px" }}
                    value={sizeAttributeName}
                    onChange={({ target: t }) => {
                      openWarningMagento();
                      setSizeAttributeName(t.value);
                    }}
                  />
                  {invalidValues.includes("magentoValues") && (
                    <s.InvalidLabel className="fade-in">
                      {t("LIVE-TAB.SomeFieldsRequired")} <br />
                    </s.InvalidLabel>
                  )}
                </>
              )
            )
          ) : (
            <>
              <label>{t("LIVE-TAB.AllReady")}</label>
              <legend style={{ fontSize: "12px", color: "gray" }}>
                {catalogService === "interno"
                  ? t("LIVE-TAB.UsePlatformProductsSystem")
                  : t("LIVE-TAB.UseSameKeys")}
              </legend>
            </>
          )}
        </Card>
        {checkout === "interno" && (
          <Card
            title={
              <>
                <InboxOutlined style={{ marginRight: "10px" }} />
                {t("LIVE-TAB.DeliveryMethods")}
              </>
            }
            style={{ margin: "5px", width: "340px" }}
            className="shadow"
          >
            <label>{t("LIVE-TAB.AvailableShippingTypes")} *</label>
            <Radio.Group
              required
              style={{ display: "grid" }}
              value={tipoFrete}
              onChange={({ target: t }) => {
                if (
                  t.value !== "combinarVendedor" &&
                  formaEntrega?.freteGratis?.valorMinimo
                ) {
                  clearFreeShippingValues();
                }
                setTipoFrete(t.value);
              }}
            >
              <Radio value={"nenhum"}>{t("LIVE-TAB.NoShipping")}</Radio>
              <Radio value={"freteProprio"}>{t("LIVE-TAB.OwnShipping")}</Radio>
              <Radio value={"correios"}>{t("LIVE-TAB.PostOffices")}</Radio>
              {checkout === "interno" && layoutCheckout === "padrao" && (
                <Radio value={"combinarVendedor"}>
                  {t("LIVE-TAB.SellerCombination")}
                </Radio>
              )}
            </Radio.Group>
            <legend
              style={{ fontSize: "12px", color: "gray", display: "grid" }}
            >
              {tipoFrete === "freteProprio"
                ? t("LIVE-TAB.OwnShippingDescription")
                : tipoFrete === "correios"
                ? t("LIVE-TAB.PostOfficesDescription")
                : tipoFrete === "nenhum"
                ? t("LIVE-TAB.NoShippingDescription")
                : t("LIVE-TAB.SellerCombinationDescription")}
            </legend>
            {tipoFrete === "combinarVendedor" && (
              <div>
                <label>{t("LIVE-TAB.MinValueFreeShipping")}</label>
                <Input
                  addonBefore={unidadeMonetaria}
                  placeholder={t("LIVE-TAB.MinValueFreeShippingPlaceholder")}
                  type="number"
                  min={0}
                  onPaste={(e) => preventInvalidNumberOnPaste(e)}
                  onKeyDown={(e) => preventInvalidNumberOnKeyDown(e)}
                  onBlur={() => checkInvalidValues("minFreeShippingValue")}
                  value={formaEntrega.freteGratis?.valorMinimo}
                  style={{
                    border:
                      invalidValues.includes("minFreeShippingValue") &&
                      "1px solid red",
                  }}
                  onChange={({ target: { value: v } }) => {
                    if (invalidValues.includes("minFreeShippingValue")) {
                      setInvalidValues(
                        invalidValues.filter(
                          (x) => x !== "minFreeShippingValue"
                        )
                      );
                    }
                    if (v >= 0 && !isNaN(v)) {
                      setFormaEntrega({
                        ...formaEntrega,
                        freteGratis: { valorMinimo: parseFloat(v) },
                      });
                    }
                  }}
                />
                {invalidValues.includes("minFreeShippingValue") && (
                  <s.InvalidLabel className="fade-in">
                    {t("LIVE-TAB.MinValueFreeShippingRequired")}
                  </s.InvalidLabel>
                )}
              </div>
            )}
            {tipoFrete === "freteProprio" && (
              <>
                <Button onClick={() => setModalShipping(true)} size="small">
                  <TableOutlined />
                  {t("LIVE-TAB.ShippingTable")}
                </Button>
                <ModalShipping
                  visible={modalShipping}
                  shippingData={formaEntrega.freteProprio || shippingJson}
                  onCancel={() => setModalShipping(false)}
                  onOk={(shipping) => {
                    setFormaEntrega({
                      ...formaEntrega,
                      freteProprio: shipping,
                    });
                    setModalShipping(false);
                  }}
                  unidadeMonetaria={live?.unidadeMonetaria}
                />
              </>
            )}
            {tipoFrete === "correios" && (
              <>
                <Input
                  addonBefore={t("LIVE-TAB.Zipcode")}
                  placeholder={t("LIVE-TAB.OriginZipcode")}
                  value={
                    formaEntrega.correios?.cepOrigem
                      ? formaEntrega.correios.cepOrigem
                      : ""
                  }
                  onBlur={() => checkInvalidValues("correios")}
                  onChange={({ target: t }) => {
                    if (invalidValues.includes("correios"))
                      setInvalidValues(
                        invalidValues.filter((x) => x !== "correios")
                      );
                    setFormaEntrega({
                      ...formaEntrega,
                      correios: {
                        ...formaEntrega.correios,
                        cepOrigem: t.value.replace(/\D/g, ""),
                      },
                    });
                  }}
                  maxLength={8}
                />
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    marginTop: 5,
                  }}
                >
                  {decrescimo
                    ? t("LIVE-TAB.ShippingDiscount")
                    : t("LIVE-TAB.ShippingAddition")}
                  <InputNumber
                    placeholder={
                      decrescimo
                        ? t("LIVE-TAB.ShippingDiscount")
                        : t("LIVE-TAB.ShippingAddition")
                    }
                    min={0}
                    max={100}
                    type="number"
                    parser={(value) => value.replace(",", ".")}
                    onPaste={(e) => preventInvalidNumberOnPaste(e)}
                    onKeyDown={(e) => preventInvalidNumberOnKeyDown(e)}
                    defaultValue={
                      formaEntrega.correios?.percentage
                        ? formaEntrega.correios.percentage
                        : 0
                    }
                    style={{ width: "75px", marginLeft: "auto" }}
                    maxLength={5}
                    onChange={(v) => {
                      if (v >= 0 && !isNaN(v)) {
                        setFormaEntrega({
                          ...formaEntrega,
                          correios: {
                            ...formaEntrega.correios,
                            percentage: parseFloat(v),
                          },
                        });
                      }
                    }}
                  />

                  <span style={{ color: "gray", marginLeft: 5 }}>%</span>
                </div>
                {invalidValues.includes("correios") && (
                  <s.InvalidLabel className="fade-in">
                    {t("LIVE-TAB.OriginZipcodeRequired")}
                  </s.InvalidLabel>
                )}

                <div style={{ marginBottom: "10px" }}>
                  {t("LIVE-TAB.ShippingDiscountValue")}
                </div>
                <Switch
                  checked={decrescimo}
                  onChange={(value) => setDecrescimo(value)}
                  style={{ width: 30, marginBottom: "10px", display: "grid" }} //decréscimo do frete
                />
              </>
            )}

            {["correios", "freteProprio"].includes(tipoFrete) && (
              <>
                <div style={{ marginTop: "10px" }}>
                  {t("LIVE-TAB.ShippingSplit")}
                </div>
                <legend style={{ fontSize: "12px", color: "gray" }}>
                  {t("LIVE-TAB.ShippingSplitDescription")}
                </legend>
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    height: "30px",
                  }}
                >
                  <Switch
                    checked={splitRulesFrete ? true : false}
                    onChange={() =>
                      setSplitRulesFrete(splitRulesFrete ? false : {})
                    }
                    style={{ width: 30 }}
                  />
                  {splitRulesFrete && (
                    <>
                      <Button
                        size="small"
                        style={{ marginLeft: "10px" }}
                        onClick={() => setModalSplitFrete(true)}
                      >
                        {t("LIVE-TAB.ManageSplit")}
                      </Button>
                      <ModalSplitFrete
                        visible={modalSplitFrete}
                        splitRules={
                          splitRulesFrete && splitRulesFrete.length > 0
                            ? splitRulesFrete
                            : []
                        }
                        onCancel={() => setModalSplitFrete(false)}
                        onOk={(newSplitRulesFrete) => {
                          setSplitRulesFrete(newSplitRulesFrete);
                          setModalSplitFrete(false);
                        }}
                      />
                    </>
                  )}
                </div>
              </>
            )}

            <div style={{ marginTop: "10px" }}>{t("LIVE-TAB.PickAtSpot")}</div>
            <legend style={{ fontSize: "12px", color: "gray" }}>
              {t("LIVE-TAB.PickAtSpotDescription")}
            </legend>
            <div
              style={{ display: "flex", alignItems: "center", height: "30px" }}
            >
              <Switch
                checked={retirada ? true : false}
                onChange={() => setRetidada(retirada ? false : {})}
                style={{ width: 30 }}
              />
              {retirada && (
                <>
                  <Button
                    size="small"
                    style={{ marginLeft: "10px" }}
                    onClick={() => setModalRetirada(true)}
                  >
                    <EnvironmentOutlined />
                    {t("LIVE-TAB.PickupAddress")}
                  </Button>
                  <span style={{ marginLeft: "10px" }}>*</span>
                  <ModalRetirada
                    visible={modalRetirada}
                    onOk={(data) => {
                      setRetidada(data);
                      setModalRetirada(false);
                    }}
                    retirada={retirada}
                    onCancel={() => setModalRetirada(false)}
                  />
                </>
              )}
            </div>
          </Card>
        )}
        <Card
          title={
            <>
              <TagOutlined style={{ marginRight: "10px" }} />
              TAGS
            </>
          }
          style={{ margin: "5px", width: "340px" }}
          className="shadow"
        >
          <label>{t("LIVE-TAB.ConfigureGoogleTags")}</label>
          <legend style={{ fontSize: "12px", color: "gray" }}>
            {t("LIVE-TAB.ConfigureGoogleTagsDescription")}{" "}
            <b>{t("LIVE-TAB.Events")}</b>
          </legend>
          <legend style={{ fontSize: "12px", color: "gray" }}>
            <b>{t("LIVE-TAB.Events")}</b> {t("LIVE-TAB.EventsDescription")}:{" "}
            <i>
              <b>{t("LIVE-TAB.EventsCaptured")}</b>
            </i>
            , {t("LIVE-TAB.AndOthers")}
          </legend>
          <Input
            placeholder="Google Tag ID"
            style={{ marginTop: "5px" }}
            value={googleTag}
            onChange={({ target: t }) => setGoogleTag(t.value)}
            addonBefore="GTM-"
          />
        </Card>
      </div>
      <Button
        type="primary"
        onClick={finish}
        style={{ margin: "20px 0" }}
        disabled={checkFinishIsDisabled()}
        loading={saving}
      >
        {saving
          ? live
            ? t("LIVE-TAB.Saving")
            : t("LIVE-TAB.Creating")
          : live
          ? t("LIVE-TAB.Save")
          : t("LIVE-TAB.Next")}
        {!saving ? live ? <SaveOutlined /> : <ArrowRightOutlined /> : <></>}
      </Button>
    </div>
  );
};
export default withRouter(LiveTab);
