import React, { useContext, useState, useEffect, useLayoutEffect } from "react";
import { useTranslation } from "react-i18next";
import * as s from "./styles-products";
import { message, Button, Drawer, Modal } from "antd";
import {
  CaretRightOutlined,
  CaretLeftOutlined,
  ArrowLeftOutlined,
  CloseOutlined,
} from "@ant-design/icons";
import Carousel, { consts } from "react-elastic-carousel";
import Scrollbars from "react-custom-scrollbars";
import {
  customerService,
  firestore,
  googleTagManager,
  managerService,
  productService,
} from "../../../services";
import {
  AddProductContext,
  GradesContext,
  ThemeContext,
  UserContext,
  useTabsNavigation,
  ShowProductContext,
} from "../../../context";
import { ModalVariations, DrawerProduct } from "../../../components";
import {
  formatCash,
  shouldAppear,
  sourceImage,
  duas_casas,
} from "../../../utils";
import ReactCardFlip from "react-card-flip";

const Products = ({ live }) => {
  const {
    tabActive,
    handleChangeTabActive,
    mainProductDocId,
    setMainProductDocId,
    mainProductId,
    setMainProductId,
    previousTabActive,
  } = useTabsNavigation();
  const { t } = useTranslation();
  const { corPrimaria, corSecundaria } = useContext(ThemeContext);
  const externalCheckout = live.checkout === "externo";

  const { show, show2 } = useContext(ShowProductContext);
  const { user } = useContext(UserContext); //dados do usuário
  const { userGrades } = useContext(GradesContext); //grades do usuário
  const { addProduct, setAddProduct } = useContext(AddProductContext);
  const [products, setProducts] = useState([]); //todos os produtos da live
  const [cart, setCart] = useState([]); //ids dos produtos favoritos do usuário
  //control
  const [adding, setAdding] = useState([]); //ids dos produtos que estão sendo adicionados ao carrinho
  const [verticalDrawer, setVerticalDrawer] = useState(false); //se o drawer da vertical está aberto
  const [modalVariationSoldOff, setModalVariationSoldOff] = useState(false);
  const [videoTime, setVideoTime] = useState("");
  const [startTime, setStartTime] = useState();
  const [showingNow, setShowingNow] = useState(false);
  const [spinProductTimeout, setSpinProductTimeout] = useState(false);
  const [windowMeasures, setWindowMeasures] = useState({
    width: window.innerWidth,
    height: window.innerHeight,
  });

  useEffect(() => {
    managerService
      .getTransmissionStartTime(live.liveId)
      .then(({ startTime }) => setStartTime(new Date(startTime).getTime()));
  }, []);

  useLayoutEffect(() => {
    window.addEventListener("resize", resizeWindow);
    return () => {
      window.removeEventListener("resize", resizeWindow);
    };
  }, []);

  const resizeWindow = () => {
    setWindowMeasures({ width: window.innerWidth, height: window.innerHeight });
  };

  const breakPoints = [
    {
      width: 450,
      itemsToShow: 3,
      enableSwipe: true,
      enableMouseSwipe: true,
      itemsToScroll: 3,
      renderArrow: () => <div />,
    },
    {
      width: 500,
      itemsToShow: 3,
    },
    { width: 620, itemsToShow: 4 },
    { width: 720, itemsToShow: 5 },
    { width: 850, itemsToShow: 6 },
    { width: 1150, itemsToShow: 7 },
  ];

  const checkMainProductExist = (time) => {
    let hora = duas_casas(Math.trunc(time / 3600));
    let minuto = duas_casas(Math.trunc((time % 3600) / 60));
    let segundo = duas_casas((time % 3600) % 60).substring(0, 2);
    if (segundo === "9.") segundo = "09";
    let timeVideo = hora + ":" + minuto + ":" + segundo;

    if (!show && !show2) {
      clearTimeout(spinProductTimeout);
      setShowingNow(false);
    }
    // verifica se o tempo do video esta dentro do tempoInicio/tempoFim do show
    else if (
      timeVideo >= show?.tempoInicio &&
      timeVideo <= show?.tempoFim &&
      showingNow !== show
    ) {
      setShowingNow(show);
    } else if (
      // verifica se o tempo do video esta dentro do tempoInicio/tempoFim do show2
      timeVideo >= show2?.tempoInicio &&
      timeVideo <= show2?.tempoFim &&
      showingNow !== show2
    ) {
      setShowingNow(show2);
    }
  };

  useEffect(() => {
    if (document.getElementById("video")) {
      if (!live?.aoVivo && !live?.realtime) {
        let videoTime = document.getElementById("video").currentTime;
        checkMainProductExist(videoTime);
      }
    }
  }, [show, show2]);

  useEffect(() => {
    if (showingNow) {
      if (spinProductTimeout) clearTimeout(spinProductTimeout);

      setSpinProductTimeout(
        // funcao de girar
        setTimeout(() => {
          let whatToShowNext =
            showingNow === show && show2
              ? show2
              : showingNow === show && !show2
              ? show
              : showingNow === show2 && show
              ? show
              : show2;
          setShowingNow(whatToShowNext);
        }, 10000)
      );
    }
  }, [showingNow]);

  const isFlipped = () => {
    if (showingNow === show) return false;
    else {
      if (show === showingNow) return false;
      return true;
    }
  };

  useEffect(() => {
    if (document.getElementById("video")) {
      document.getElementById("video").ontimeupdate = function () {
        if (this.currentTime) setVideoTime(this.currentTime);
      };
    }
  }, [show, show2]);

  useEffect(() => {
    if (live && !live.tabelaPreco) {
      let subscribe = firestore
        .collection(`lives/${live.liveId}/produtos`)
        .onSnapshot((snapshot) => {
          let productsToSave = snapshot.docs
            .map(
              (doc) =>
                doc.data() &&
                doc.data().showOptions?.when !== "never" && {
                  ...doc.data(),
                  preco: parseFloat(doc.data().preco / 100),
                  precoOriginal: parseFloat(doc.data().precoOriginal / 100),
                  docId: doc.id,
                }
            )
            .filter((products) => {
              if (products.controlStock === true) {
                let stockFiltered = products.stock.filter(
                  (value, { stock }) => stock !== 0 && value
                );
                products.showStock = Math.min(...stockFiltered);
                return products;
              } else {
                return products;
              }
            })
            .sort((a, b) => a.id - b.id);
          if (!live.realtime) {
            let productsWithTime = productsToSave.filter((x) => x.tempoInicio);
            let otherProducts = productsToSave.filter((x) => !x.tempoInicio);
            productsWithTime = productsWithTime.sort((a, b) => {
              if (a.tempoInicio) {
                if (b.tempoInicio > a.tempoInicio) return -1;
                else return 1;
              }
            });
            setProducts([...productsWithTime, ...otherProducts]);
          } else setProducts(productsToSave);
        });
      return () => subscribe();
    }
  }, []);

  useEffect(() => {
    if (live?.tabelaPreco && user?.idTabelaPreco) {
      let subscribe3 = firestore
        .collection(`lives/${live.liveId}/produtos`)
        .where("tabelaPreco", "==", user.idTabelaPreco)
        .onSnapshot((snapshot) => {
          setProducts(
            snapshot.docs
              .map(
                (doc) =>
                  doc.data().principal && {
                    ...doc.data(),
                    preco: parseFloat(doc.data().preco / 100),
                    precoOriginal: parseFloat(doc.data().precoOriginal / 100),
                    docId: doc.id,
                  }
              )
              .filter((p) => p)
              .sort((a, b) => a.id - b.id)
          );
        });
      return () => subscribe3();
    }
  }, [user]);

  useEffect(() => {
    if (user && (user.email || (user.hash && user.anonimo))) {
      let userCod = user.codigo || user.hash;
      let subscribe2 = firestore
        .collection("lives")
        .doc(live?.liveId)
        .collection("favoritos")
        .where("codigo", "==", userCod)
        .onSnapshot((snapshot) => {
          setCart(snapshot.docs.map((doc) => doc.data().produto.docId));
        });
      //@todo ver como fica lista assinatura
      if (live?.listaAssinatura) handleAssinatura(user?.email);
      return () => subscribe2();
    }
  }, [user]);

  useEffect(() => {
    if (products.length > 0) {
      products.map((product) => {
        if (product.controlStock && product.stock) {
          let db = firestore.collection(`lives/${live.liveId}/produtos`);
          let count = 0;
          for (let i = 0; i < product.stock.length; i++) {
            if (product.stock[i] === 0) count++;
          }
          if (count === product.tamanhos.length)
            db.doc(product.docId).update({ esgotado: true });
          else db.doc(product.docId).update({ esgotado: false });
        }
      });
    }
  }, [products]);

  useEffect(() => {
    if (!modalVariationSoldOff)
      setTimeout(() => {
        setMainProductId(false);
        setMainProductDocId(false);
      }, 400); //para produto não sumir antes da modal fechar
  }, [modalVariationSoldOff]);

  const handleAssinatura = (email) => {
    customerService.verifyAssinatura(live.liveId, email).catch(() =>
      Modal.confirm({
        title: t("PRODUCTS.LiveWithSubscription"),
        content: t("PRODUCTS.LiveWithSubscriptionDescription"),
        okText: t("PRODUCTS.GotIt"),
        cancelButtonProps: { style: { display: "none" } },
      })
    );
  };

  useEffect(() => {
    if (cart && cart.length > 0 && adding && adding.length > 0)
      setAdding(adding.filter((p) => !cart.includes(p)));
  }, [cart]);

  const verifyAddProduct = (product) => {
    if (live.checkout === "externo") {
      window.open(product?.redirectLink);
    } else {
      if (
        addProduct?.docId !== product?.docId &&
        (product.tipoGrade === "fechada"
          ? !cart.includes(product?.docId)
          : true)
      ) {
        product.available === false && !product.esgotado
          ? message.error(t("PRODUCTS.ProductUnavailableToBuy"))
          : product.esgotado
          ? openVariationModal(product)
          : handleAddProductToCart(product);
      } else
        message.success({
          content: t("PRODUCTS.ProductAlreadyInCart"),
          duration: 2,
        });
    }

    const liveId = live.liveId;
    const productId = product.docId;
    const customerId = user.id;

    if (user.anonimo) {
      managerService.recordClicksByProduct({ liveId, productId });
    } else {
      managerService.recordClicksByProduct({ liveId, productId, customerId });
    }
  };

  const renderArrow = ({ onClick, type }) => (
    <Button
      onClick={onClick}
      style={{
        margin: "auto ",
        marginLeft: "8px",
        borderRadius: "100px",
        background: corPrimaria,
        borderColor: corPrimaria,
        width: "30px",
        minWidth: "30px",
        height: "30px",
        minHeight: "30px",
        padding: 0,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
      type="primary"
    >
      {type === consts.PREV ? (
        <CaretLeftOutlined style={{ margin: "2px 2px 0 0" }} />
      ) : (
        <CaretRightOutlined style={{ margin: "2px 0 0 2px" }} />
      )}
    </Button>
  );

  const openVariationModal = (p) => {
    if (window.innerWidth > 955) setModalVariationSoldOff(true);
    else {
      handleChangeTabActive("variations");
      setAddProduct(false);
    }
    setMainProductId(p.id);
    setMainProductDocId(p.docId);
  };

  const checkStockProductToAdd = (prod) => {
    if (prod.controlStock) {
      let foundAvailableSize = false;
      let response = [];
      prod.tamanhos.map((_tam, idx) => {
        if (!foundAvailableSize && prod.stock[idx] !== 0) {
          foundAvailableSize = true;
          response = [{ tamanho: prod.tamanhos[idx], quantidade: 1 }];
        }
      });
      return response;
    } else return [{ tamanho: prod.tamanhos[0], quantidade: 1 }];
  };

  const handleAddProductToCart = (product) => {
    if (
      !adding.includes(product.docId) &&
      user &&
      (user.email || (user.hash && user.anonimo))
    ) {
      googleTagManager.clickProduct(product, "products");
      if (product.tipoGrade === "aberta" && live.layoutCheckout !== "padrao")
        setAddProduct(product);
      else {
        if (cart.includes(product.docId))
          message.warning({
            content: t("PRODUCTS.ProductAlreadyInCart"),
            duration: 2,
            key: "add-product-to-cart",
          });
        else {
          if (adding.includes(product.docId))
            setAdding(adding.filter((id) => id !== product.docId));
          else setAdding([...adding, product.docId]);
          let newProduct = {
            ...product,
            preco: parseFloat((product.preco * 100).toFixed(2)),
            precoOriginal: parseFloat((product.precoOriginal * 100).toFixed(2)),
            grade:
              product.tipoGrade === "aberta"
                ? checkStockProductToAdd(product)
                : userGrades.filter((g) =>
                    product.grades.includes(g.gradeId)
                  )[0].grade || [],
            gradeId:
              product.tipoGrade === "aberta"
                ? null
                : userGrades.filter((g) =>
                    product.grades.includes(g.gradeId)
                  )[0].gradeId || null,
          };
          productService
            .addFavorite(live.liveId, {
              produto: newProduct,
              customer: { ...user, codigo: user.codigo || user.hash },
            })
            .then(() => {
              googleTagManager.addProductToCart(newProduct, "products");
              message.success({
                content: t("PRODUCTS.ProductAdded"),
                duration: 2,
                key: "add-product-to-cart",
              });
              setAdding(adding.filter((id) => id !== product.docId));
            });
        }
      }
    } else if (!user?.name) {
      //@todo ver como fica
      document.getElementById(`btn-login-customer`).click();
      message.info({
        content: t("PRODUCTS.LoginToLike"),
        duration: 2,
        key: "add-product-to-cart",
      });
    }
  };

  const handleProductVariations = () => {
    if (live?.layoutCheckout === "padrao")
      return products
        .filter((p) => p.id === mainProductId)
        .filter((p) => !p.principal)
        .filter((p) => addProduct?.docId !== p.docId)
        .filter((p) => !cart.map((f) => f).includes(p.docId));
    else {
      let productVariations = [];
      if (modalVariationSoldOff) {
        if (modalVariationSoldOff.tipoGrade === "fechada") {
          productVariations = products
            .filter((p) => p.id === mainProductId)
            .filter((p) => !p.principal)
            .filter((p) => addProduct?.docId !== p.docId)
            .filter((p) => !cart.map((f) => f).includes(p.docId));
        } else {
          productVariations = products
            .filter((p) => p.id === mainProductId)
            .filter((p) => !p.principal)
            .filter((p) => addProduct?.docId !== p.docId);
        }
      } else {
        productVariations = products
          .filter((p) => p.id === mainProductId)
          .filter((p) => !p.principal)
          .filter((p) => addProduct?.docId !== p.docId);
      }

      productVariations.map((v, idx) => {
        productVariations[idx] = {
          ...v,
          grade:
            v.tipoGrade === "aberta"
              ? [{ tamanho: v.tamanhos[0], quantidade: 1 }]
              : userGrades.filter((g) => v.grades.includes(g.gradeId))[0]
                  .grade || [],
          gradeId:
            v.tipoGrade === "aberta" || !v.tipoGrade
              ? null
              : userGrades.filter((g) => v.grades.includes(g.gradeId))[0]
                  .gradeId || null,
        };
      });
      return productVariations.filter((x) => x.grade.length > 0);
    }
  };

  const renderModal = () => (
    <ModalVariations
      live={live}
      customer={user}
      products={handleProductVariations()}
      visible={modalVariationSoldOff}
      onCancel={() => setModalVariationSoldOff(false)}
      hideText={addProduct?.docId === mainProductDocId}
    />
  );

  const renderProducts = (variations) => {
    if (products) {
      let productsToShow = [];
      if (variations?.length > 0) productsToShow = [...variations];
      else if (variations?.length === 0) return <></>;
      else productsToShow = products.filter((x) => x.principal);
      return productsToShow.map((p) =>
        shouldAppear(p, live, videoTime, startTime) ? (
          <div key={p.docId} className="products-prod fade-in">
            <img
              onClick={() => verifyAddProduct(p)}
              alt={p.descricao}
              src={sourceImage(p.thumbnail)}
              className={`shadow2 
              ${
                !cart.includes(p.docId) && adding.includes(p.docId)
                  ? `pulse`
                  : ``
              }
              ${p.esgotado ? `product-img-grayscale` : `product-img`}`}
            />
            <div className="content-description">
              <div className="content-price">
                <h3
                  style={{
                    fontSize: "13px",
                    fontWeight: "bold",
                  }}
                >
                  {live?.unidadeMonetaria || "R$"}
                  {p.preco && formatCash(p.preco, true, live?.unidadeMonetaria)}
                </h3>
                {p.precoOriginal && p.precoOriginal !== p.preco ? (
                  <div
                    style={{
                      textAlign: "end",
                      fontSize: "13px",
                    }}
                  >
                    <h5
                      style={{ textDecoration: "line-through", color: "gray" }}
                    >
                      {live?.unidadeMonetaria || "R$"}
                      {p.precoOriginal &&
                        formatCash(
                          p.precoOriginal,
                          true,
                          live?.unidadeMonetaria
                        )}
                    </h5>
                  </div>
                ) : null}
              </div>
              <div
                style={{
                  display: "flex",
                  justifyContent: "flex-end",
                  marginTop: "-4px",
                }}
              >
                {p.available === false || p.esgotado ? (
                  <h5 style={{ color: "red" }}>Indisponível</h5>
                ) : (
                  <h5 style={{ color: "green" }}>Disponível</h5>
                )}
              </div>
              <hr />
              {live.showStockOnLive &&
              p?.controlStock &&
              p.stock?.length !== 0 ? (
                <article
                  className="product-price"
                  style={{
                    marginTop: window.innerWidth >= 955 ? "14px" : "7px",
                  }}
                >
                  <h5
                    style={{
                      marginTop: window.innerWidth >= 955 ? "-6px" : "2px",
                    }}
                  >
                    {t("MODAL-VARIATIONS-LIVE.Quantity") + ": "}
                  </h5>
                  <h4
                    style={{
                      marginTop: window.innerWidth >= 955 ? "-6px" : "2px",
                    }}
                  >
                    {"  (" + p.stock + ")"}
                  </h4>
                </article>
              ) : (
                <br />
              )}
              <Button
                className={
                  live.showStockOnLive &&
                  p?.controlStock &&
                  p.stock?.length !== 0
                    ? `button-add`
                    : `button-add-quantity`
                }
                type="primary"
                onClick={() => verifyAddProduct(p)}
                size="large"
              >
                {externalCheckout ? "Ver Produto" : t("DRAWER.Add")}
                {externalCheckout ? (
                  <img
                    src="/assets/icons/outArrow.svg"
                    style={{ marginLeft: "10px", marginTop: "-3px" }}
                  />
                ) : (
                  <img
                    src="/assets/icons/cartOutlined.svg"
                    style={{ marginLeft: "5px", marginTop: "-3px" }}
                  />
                )}
              </Button>
            </div>
          </div>
        ) : (
          <div className="no-products-yet" key={p.docId}>
            {window.innerWidth > 955 && (
              <article>
                <label>?</label>
              </article>
            )}
            <label>{t("PRODUCTS.HiddenProduct")}</label>
            <span>{t("PRODUCTS.KeepWatching")}</span>
          </div>
        )
      );
    }
  };

  const renderShowingNowProduct = (showingNowProduct) => (
    <div
      id="showing-now"
      className="showing-now fade-in"
      style={{ margin: "20px 0", marginLeft: "20px", marginRight: "16px" }}
    >
      <img
        onClick={() => verifyAddProduct(showingNowProduct)}
        alt={showingNowProduct.descricao}
        src={sourceImage(showingNowProduct.thumbnail)}
        className={`shadow2 fade-in product-img ${
          !cart.includes(showingNowProduct.docId) &&
          adding.includes(showingNowProduct.docId)
            ? `pulse`
            : ``
        }`}
        style={{
          maxWidth: "80%",
          width: "64%",
          objectFit: "scale-down",
        }}
      />
      <article className="product-price">{t("PRODUCTS.ShowingNow")}</article>
    </div>
  );
  const renderContent = () => (
    <>
      {window.innerWidth > 955 && renderModal()}
      <s.Title className="fade-in">
        <img src="/assets/icons/products.svg" />
        {t("PRODUCTS.LiveProducts")}
      </s.Title>
      <s.ProductsContainer
        className="fade-in"
        corPrimaria={corPrimaria}
        corSecundaria={corSecundaria}
        vertical={live?.vertical}
      >
        {live.realtime === true && show ? renderShowingNowProduct(show) : null}
        {live.realtime === true && show2
          ? renderShowingNowProduct(show2)
          : null}
        {showingNow && (
          <s.CardFlipContainer vertical={live?.vertical}>
            <ReactCardFlip
              isFlipped={isFlipped()}
              disabled={(show && !show2) || (show2 && !show)}
              flipDirection="horizontal"
            >
              {window.innerWidth < 955 && renderShowingNowProduct(show)}
              {window.innerWidth < 955 && show2 ? (
                renderShowingNowProduct(show2)
              ) : (
                <div></div>
              )}
            </ReactCardFlip>
            {window.innerWidth < 955 && (
              <s.ShowingNowGradient vertical={live?.vertical} />
            )}
          </s.CardFlipContainer>
        )}

        {window.innerWidth > 955 ? (
          <Carousel
            itemsToShow={7}
            renderArrow={renderArrow}
            enableMouseSwipe={false}
            pagination={false}
            breakPoints={[
              {
                width: 450,
                itemsToShow: 3,
                enableSwipe: true,
                enableMouseSwipe: true,
                itemsToScroll: 3,
                renderArrow: () => <div />,
              },
              {
                width: 500,
                itemsToShow: 3,
              },
              { width: 620, itemsToShow: 3 },
              { width: 720, itemsToShow: 4 },
              { width: 850, itemsToShow: 5 },
              { width: 1150, itemsToShow: 7 },
            ]}
          >
            {renderProducts()}
          </Carousel>
        ) : (
          <Scrollbars
            className="scrollbars"
            style={{
              height:
                windowMeasures.width <= 320 && !live?.vertical
                  ? 280
                  : windowMeasures.width < 350 && live?.vertical
                  ? 100
                  : windowMeasures.width <= 375 && !live?.vertical
                  ? 280
                  : windowMeasures.width < 375 && live?.vertical
                  ? 130
                  : windowMeasures.width <= 425 && !live?.vertical
                  ? 280
                  : windowMeasures.width <= 425 && live?.vertical
                  ? 280
                  : windowMeasures.width < 470
                  ? 140
                  : windowMeasures.width < 955
                  ? 160
                  : 180,
            }}
            renderThumbVertical={() => <div style={{ display: "none" }} />}
            renderTrackVertical={() => <div style={{ display: "none" }} />}
          >
            {renderProducts()}
          </Scrollbars>
        )}
      </s.ProductsContainer>
    </>
  );

  if (window.innerWidth < 955) {
    if (live.vertical) {
      if (tabActive === "variations") {
        let isProductSoldOff = false;
        let variationsOfThisProduct = products
          .filter((p) => p.id === mainProductId)
          .filter((p) => !p.principal)
          .filter((p) => !cart.map((f) => f).includes(p.docId));

        if (
          products[products.findIndex((x) => x.docId === mainProductDocId)]
            .esgotado
        )
          isProductSoldOff = true;

        return (
          <s.ProductsVertical>
            <DrawerProduct
              live={live}
              products={products}
              openVariations={openVariationModal}
              unidadeMonetaria={live?.unidadeMonetaria}
            />
            <Drawer
              placement="bottom"
              closable
              onClose={() => handleChangeTabActive(previousTabActive)}
              visible={tabActive === "variations"}
              style={{ padding: 0, zIndex: 10 }}
              bodyStyle={{
                padding: 0,
                background: "rgba(248, 248, 248, 1)",
                overflow: "inherit",
              }}
              headerStyle={{ display: "none" }}
              maskStyle={{ background: "transparent" }}
              height={variationsOfThisProduct.length > 0 ? 328 : 1500}
            >
              <>
                <CloseOutlined
                  style={{
                    position: "absolute",
                    top: 0,
                    right: 0,
                    padding: 20,
                  }}
                  onClick={() => handleChangeTabActive(previousTabActive)}
                />
                <s.Title className="fade-in">
                  {t("PRODUCTS.Variations")}
                </s.Title>
                {isProductSoldOff && variationsOfThisProduct.length > 0 ? (
                  <s.SubTitle style={{ marginLeft: 20 }}>
                    {t("PRODUCTS.ProductSoldoffVariations")}
                  </s.SubTitle>
                ) : (
                  variationsOfThisProduct.length === 0 && (
                    <s.SubTitle style={{ marginLeft: 20 }}>
                      {t("PRODUCTS.SoldoffNoAvailableVariations")}
                    </s.SubTitle>
                  )
                )}
                <s.ProductsContainer
                  className="fade-in"
                  corPrimaria={corPrimaria}
                  corSecundaria={corSecundaria}
                >
                  {renderProducts(variationsOfThisProduct)}
                </s.ProductsContainer>
              </>
            </Drawer>
          </s.ProductsVertical>
        );
      }
      return (
        <s.ProductsVertical>
          {/* <ShopOutlined
            onClick={() => setVerticalDrawer(true)}
            style={{
              padding: "10px 15px",
              background: "rgba(0,0,0,0.6)",
              borderRadius: 0,
              color: "rgba(255,255,255, 0.9)",
            }}
          /> */}
          <DrawerProduct
            live={live}
            products={products}
            openVariations={openVariationModal}
            unidadeMonetaria={live?.unidadeMonetaria}
          />
          <Drawer
            placement="bottom"
            closable
            onClose={() => handleChangeTabActive("chat")}
            visible={tabActive === "products"}
            style={{ padding: 0, zIndex: 9 }}
            bodyStyle={{
              padding: 0,
              background: "rgba(248, 248, 248, 1)",
              overflowY: "inherit",
            }}
            headerStyle={{ display: "none" }}
            maskStyle={{ background: "transparent" }}
            height={328}
          >
            <CloseOutlined
              style={{ position: "absolute", top: 0, right: 0, padding: 20 }}
              onClick={() => handleChangeTabActive("chat")}
            />
            <s.Title className="fade-in">
              <img src="/assets/icons/products.svg" />
              {t("PRODUCTS.LiveProducts")}
            </s.Title>
            <s.ProductsContainer
              className="fade-in"
              corPrimaria={corPrimaria}
              corSecundaria={corSecundaria}
              horizontal
            >
              {showingNow && renderShowingNowProduct(show)}
              {renderProducts()}
            </s.ProductsContainer>
          </Drawer>
        </s.ProductsVertical>
      );
    } else {
      if (tabActive === "chat")
        return (
          <>
            <DrawerProduct
              live={live}
              products={products}
              openVariations={openVariationModal}
              unidadeMonetaria={live?.unidadeMonetaria}
            />
            <s.ProductsContainer
              className="fade-in"
              corPrimaria={corPrimaria}
              corSecundaria={corSecundaria}
              horizontal
            >
              {live.realtime === true && show
                ? renderShowingNowProduct(show)
                : null}
              {live.realtime === true && show2
                ? renderShowingNowProduct(show2)
                : null}
              {showingNow && renderShowingNowProduct(show)}
              {renderProducts()}
            </s.ProductsContainer>
          </>
        );
      else if (tabActive === "products")
        return (
          <>
            <DrawerProduct
              live={live}
              products={products}
              openVariations={openVariationModal}
              unidadeMonetaria={live?.unidadeMonetaria}
            />
            <s.Title className="fade-in">
              <img src="/assets/icons/products.svg" />
              {t("PRODUCTS.LiveProducts")}
            </s.Title>
            <s.ProductsContainer
              className="fade-in"
              corPrimaria={corPrimaria}
              corSecundaria={corSecundaria}
              horizontal
            >
              {renderProducts()}
            </s.ProductsContainer>
          </>
        );
      else if (tabActive === "variations" && window.innerWidth < 955) {
        let isProductSoldOff = false;
        let variationsOfThisProduct = products
          .filter((p) => p.id === mainProductId)
          .filter((p) => !p.principal)
          .filter((p) => !cart.map((f) => f).includes(p.docId));
        if (
          products[products.findIndex((x) => x.docId === mainProductDocId)]
            .esgotado
        )
          isProductSoldOff = true;

        return (
          <>
            <DrawerProduct
              live={live}
              products={products}
              openVariations={openVariationModal}
              unidadeMonetaria={live?.unidadeMonetaria}
              className="fade-in"
            />
            <div
              style={{ display: "flex", alignItems: "center", width: "100%" }}
            >
              <ArrowLeftOutlined
                style={{
                  marginRight: "auto",
                  marginLeft: 20,
                  paddingTop: 10,
                }}
                onClick={() => handleChangeTabActive(previousTabActive)}
              />
              <s.Title style={{ marginRight: 20 }} className="fade-in">
                {t("PRODUCTS.Variations")}
              </s.Title>
            </div>
            {isProductSoldOff && variationsOfThisProduct.length > 0 ? (
              <s.SubTitle>{t("PRODUCTS.ProductSoldoffVariations")}</s.SubTitle>
            ) : (
              isProductSoldOff && (
                <s.SubTitle>
                  {t("PRODUCTS.SoldoffNoAvailableVariations")}
                </s.SubTitle>
              )
            )}
            <s.ProductsContainer
              className="fade-in"
              corPrimaria={corPrimaria}
              corSecundaria={corSecundaria}
              horizontal
            >
              {renderProducts(variationsOfThisProduct)}
            </s.ProductsContainer>
          </>
        );
      } else return <></>;
    }
  } else
    return (
      <>
        <DrawerProduct
          live={live}
          products={products}
          openVariations={openVariationModal}
          unidadeMonetaria={live?.unidadeMonetaria}
        />
        {renderContent()}
      </>
    );
};

export default Products;
