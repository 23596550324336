import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import * as s from "./styles";
import { Card, Button, notification, message } from "antd";
import { CopyOutlined, KeyOutlined, LinkOutlined } from "@ant-design/icons";
import { managerService } from "../../../services";

const TransmitionTab = ({ live }) => {
  const { t } = useTranslation();
  //Control
  const [configs, setConfigs] = useState(false); //configs de transmissão
  const [loading, setLoading] = useState(false); //buscando configs

  useEffect(() => {
    managerService
      .getTransmitionConfigs(live.docId)
      .then((d) => {
        setConfigs(d);
        setLoading(false);
      })
      .catch(() => {
        notification.error({
          key: "aviso",
          message: t("TRANSMISSION-TAB.GeneralError"),
          description: t("TRANSMISSION-TAB.TrasmissionConfigErrorDescription"),
          onClick: () => notification.close("aviso"),
          duration: 10,
        });
        setLoading(false);
      });
  }, [live]);

  return (
    <s.Container className="fade-in">
      <Card
        title={
          <>
            <LinkOutlined style={{ marginRight: "10px" }} />
            {t("TRANSMISSION-TAB.TransmissionLink")}
          </>
        }
        style={{ width: "600px", margin: "5px" }}
        className="shadow"
      >
        <p>{t("TRANSMISSION-TAB.TransmissionLinkDescription")}</p>
        <Button
          type="primary"
          onClick={() => {
            navigator.clipboard
              .writeText(configs.streamUrl)
              .then(() => message.success(t("TRANSMISSION-TAB.LinkCopied")));
          }}
          disabled={!configs.streamUrl || loading}
          style={{ marginTop: "20px" }}
        >
          <CopyOutlined style={{ marginRight: "10px" }} />
          <label id="url-live">
            {configs.streamUrl || t("TRANSMISSION-TAB.NotAvailable")}
          </label>
        </Button>
      </Card>
      <Card
        title={
          <>
            <KeyOutlined style={{ marginRight: "10px" }} />
            {t("TRANSMISSION-TAB.TransmissionKey")}
          </>
        }
        style={{ width: "600px", margin: "5px" }}
        className="shadow"
      >
        <p>{t("TRANSMISSION-TAB.TransmissionKeyDescription")}</p>
        <Button
          type="primary"
          onClick={() => {
            navigator.clipboard
              .writeText(configs.streamKey)
              .then(() => message.success("Link copiado!"));
          }}
          disabled={!configs.streamKey || loading}
          style={{ marginTop: "20px" }}
        >
          <CopyOutlined style={{ marginRight: "10px" }} />
          <label id="url-live">
            {configs.streamKey || t("TRANSMISSION-TAB.NotAvailable")}
          </label>
        </Button>
      </Card>
    </s.Container>
  );
};

export default TransmitionTab;
